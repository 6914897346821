import React from "react";
import PropTypes from 'prop-types';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

const defaultToolbarStyles = {
  iconButton: {}
};

const ListToolbar = (props) => {

  return (
    <>
      {
        props.onAddPress &&
        <Tooltip title={props.addTitle}>
          <IconButton onClick={props.onAddPress}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      }
      {
        props.onSelectionPress &&
        <Tooltip title={props.selectionTitle}>
          <IconButton onClick={props.onSelectionPress}>
            <PlaylistAddCheckIcon />
          </IconButton>
        </Tooltip>
      }
    </>
  );
};

ListToolbar.propTypes = {
  addTitle: PropTypes.string,
  onAddPress: PropTypes.func,
  selectionTitle: PropTypes.string,
  onSelectionPress: PropTypes.func,
};

export default withStyles(defaultToolbarStyles)(ListToolbar);
