import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import MUIDataTable from "mui-datatables";
import {TableRow, TableCell, Dialog, DialogTitle,DialogContent,Grid, Tooltip, IconButton} from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import {FittedUserModel} from "../../models/fitted_users";
import FittedUserForm from './fitted_user_form';
import MasterLayout from "../layouts/master";
import Role from "../../models/role";


const FittedUsersPage = (props) => {

  const [create_mode, set_create_mode] = useState(false);

  useEffect(() => {
    if(!props.fitted_users.items)
      FittedUserModel.get_fitted_users();
  }, [props.fitted_users.items]);

  if(!props.fitted_users.items)
    return <b>Loading ...</b>;

  return (
    <MasterLayout title='Fitted Users'>
      <Dialog
        scroll='body'
        fullWidth
        maxWidth='sm'
        open={create_mode}
        onClose={() => set_create_mode(false)}
      >
        <DialogTitle>Create New Fitted User</DialogTitle>
        <DialogContent>
          <FittedUserForm onClose={() => set_create_mode(false)} />
        </DialogContent>
      </Dialog>

      <MUIDataTable
        title="Fitted Users"
        data={props.fitted_users.items}
        columns={FittedUserModel.columns}
        options={{
          filterType: "dropdown",
          responsive: "scrollFullHeight",  // scrollMaxHeight | scrollFullHeight | stacked
          print: false,
          selectableRows: Role.user_has_capability(Role.capabilities.delete_fitted_users) ? 'multiple' : 'none',
          selectableRowsOnClick: false,
          onRowsDelete: (rowsDeleted) => {
            if(Role.user_has_capability(Role.capabilities.delete_fitted_users)) {
              rowsDeleted.data.forEach((row) => {
                const user = props.fitted_users.items[row.dataIndex];
                FittedUserModel.delete_fitted_user(user.id);
              });
            }
          },
          expandableRows: Role.user_has_capability(Role.capabilities.update_fitted_users),
          customToolbar: () => (
            Role.user_has_capability(Role.capabilities.create_fitted_users) &&
            <Tooltip title='Create Fitted User'>
              <IconButton onClick={() => set_create_mode(true)}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          ),
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            return (
              <TableRow style={{backgroundColor: '#eee'}}>
                <TableCell colSpan={colSpan}>
                  <Grid container style={{justifyContent: 'center'}}>
                    <Grid container item sm={12} md={6} >
                      <div className='header'>
                        <h2>Edit Fitted User</h2>
                      </div>
                      <FittedUserForm user={props.fitted_users.items[rowMeta.dataIndex]} />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            );
          },
        }}
      />
    </MasterLayout>
  );
};

FittedUsersPage.propTypes = { };

const mapStateToProps = state => ({
  fitted_users: {
    ...state.fitted_users
  },
});

export default connect(mapStateToProps)(FittedUsersPage);
