import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {connect} from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import {Chip, Checkbox, ListSubheader} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import VirtualList from '../../../../components/virtual_list/virtual_list';
import PropTypes from 'prop-types';
import {StoreShoesModel} from "../../../../models/store_shoes";


const useStyles = makeStyles({
  listbox: {
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const BrandsFilter = ({store_id, values, on_change}) => {
  const classes = useStyles();
  const [store_brands, set_store_brands] = useState([]);

  useEffect(() => {
    StoreShoesModel
      .get_store_visible_shoes(store_id)
      .then((store_shoes) => {
        const store_brands = {};
        store_shoes.forEach(shoe => {
          store_brands[shoe.brand] = true;
        });
        set_store_brands(Object.keys(store_brands).map(s => ({id: s, name: s})));
      });
  }, [store_id]);

  return (
    <Autocomplete
      disableListWrap
      classes={classes}
      style={{marginTop: 20}}
      ListboxComponent={VirtualList}
      multiple margin='dense' size="small"
      disableCloseOnSelect
      options={store_brands}
      // value={values}
      defaultValue={values}
      getOptionLabel={option => option.name}
      getOptionSelected={(option, value) => (option.id === value.id)}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox key={option.id}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </React.Fragment>
      )}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label="Brands"
          fullWidth
        />
      )}
      renderTags={value => (
        value.length === 1 ?
          <Chip size='small' label={value[0].name} /> : <Chip size='small' label={value.length + ' Brands'} />
      )}
      onChange={(e, values) => {
        on_change(values.map(v => ({
          id: v.id,
          name: v.name
        })));
      }}
      />
  );
};

BrandsFilter.propTypes = {
  store_id: PropTypes.string.isRequired,
  on_change: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  store_shoes: state.store_shoes
});

export default connect(mapStateToProps)(BrandsFilter);
