import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import MasterLayout from "../layouts/master";
import Role from "../../models/role";
import StoreSurveyForm from "../common/store_survey_form";


const StoreSurveyPage = (props) => {
  return (
    <MasterLayout title='Store Survey'>
      {
        props.stores.current_store_id &&
        <StoreSurveyForm store_id={props.stores.current_store_id} survey_id={props.match.params.survey_id}
                         can_create={Role.user_has_capability(Role.capabilities.create_all_surveys)}
                         can_update={Role.user_has_capability(Role.capabilities.update_all_surveys)}
                         can_delete={Role.user_has_capability(Role.capabilities.delete_all_surveys)} />
      }
    </MasterLayout>
  );
};

StoreSurveyPage.propTypes = {};

const mapStateToProps = state => ({
  stores: state.stores
});

export default withRouter(connect(mapStateToProps)(StoreSurveyPage));
