import {createStore, combineReducers} from 'redux';
import {authReducer} from './auth';
import {roleReducer} from './role';
import {brandReducer} from "./brand";
import {shoeReducer} from "./shoe";
import {storeReducer} from "./store";
import {baseReducer} from "./base";
import {storeShoesReducer} from "./store_shoes";
import {storeLocationsReducer} from "./store_locations";
import {storeEmployeesReducer} from "./store_employee";
import {fittedUsersReducer} from "./fitted_users";
import {fittedSurveysReducer} from "./fitted_surveys";
import {storeUsersReducer} from "./store_users";
import {storeUserRolesReducer} from "./store_user_roles";
import {storeSurveysReducer} from "./store_surveys";
import {storeReportsReducer} from "./store_report";


const appReducers = combineReducers({
  base: baseReducer,
  auth: authReducer,
  role: roleReducer,

  fitted_users: fittedUsersReducer,
  fitted_surveys: fittedSurveysReducer,

  brands: brandReducer,
  shoes: shoeReducer,
  stores: storeReducer,

  store_shoes: storeShoesReducer,
  store_locations: storeLocationsReducer,
  store_employees: storeEmployeesReducer,
  store_surveys: storeSurveysReducer,
  store_users: storeUsersReducer,
  store_user_roles: storeUserRolesReducer,
  store_charts: storeReportsReducer
});


export const rootActionTypes = {
  RESET_APP: 'RESET_APP',
};


const rootReducer = (state, action) => {
  if (action.type === rootActionTypes.RESET_APP) {
    state = undefined;
  }
  return appReducers(state, action);
};


export const ReduxStore = createStore(rootReducer);
