import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import connect from "react-redux/es/connect/connect";
import firebase from 'firebase';
import PrivateRoute from './routes/private';
import config from './config';
import firebase_config from "./config/firebase";
import Auth from "./models/auth";
import LoginPage from './containers/login/login';
import FittedAdminRoutes from './routes/fitted_admin_routes';
import StoreAdminRoutes from './routes/store_admin_routes';
import StoreReport from './containers/common/report/main';
import NoPage from "./containers/no_page/no_page";
import BaseRoute from './routes/base_route';
import { SnackbarProvider } from 'notistack';

const App = (props) => {

  useEffect(() => {
    firebase.initializeApp(firebase_config);

    // firebase
    //   .firestore()
    //   .collection('stores')
    //   .doc('QF5jKaKYVkNfPPKJzXpC')
    //   .onSnapshot((doc) => {
    //     const docData = doc.data();
    //     console.log(docData);
    //   });

    // We will listen to the user's auth status and will know if user is
    // already authenticated or when user gets logged out on another tab.
    // Notice that observeUser returns an unsubscribe function
    return Auth.observeUser();
  }, []);

  useEffect(() => {
    console.log('State:', props.state);
  }, [props.state]);

  if (!props.state.auth) {
    // It takes about a second for the Auth.observeUser to decide if user is logged in or not
    // so we show an empty page until then. Later we can add a beautiful loading here.
    return null;
  }

  return (
    <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} maxSnack={5}>
      <ReactNotification/>
      <Switch>

        ////////////////// Public pages //////////////////

        <Route exact path={config.routes.login} component={LoginPage}/>


        // This Route is used by the ios app to show the plain store report without sidebar, ...
        <Route exact path={config.routes.ios_report} component={StoreReport} />

        ////////////////// Private pages //////////////////

        <PrivateRoute exact path={config.routes.base} component={BaseRoute}
                      capability={''} />

        // All routes that start with /fitted/...
        <Route path={config.routes.fitted.base} component={FittedAdminRoutes}/>

        // All routes that start with /store/...
        <Route path={config.routes.store.base} component={StoreAdminRoutes}/>

        <NoPage />

      </Switch>
    </SnackbarProvider>
  );
};

const mapStateToProps = (state) => ({
  state
});

export default connect(mapStateToProps)(App);
