import Model from "./base";
import Joi from '@hapi/joi';
import firebase from "firebase";
import {ReduxStore} from './redux';


const initialState = {
  items: [],
  loading: false,
  error: null
};

export const shoeReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_SHOES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case actionTypes.GET_SHOES_SUCCESS:
      return {
        ...state,
        items: action.payload.items.sort((a, b)=>a.name>b.name?1:(a.name<b.name?-1:0)),
        loading: false
      };

    case actionTypes.GET_SHOES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    default:
      return state;
  }
};


export const actionTypes = {
  GET_SHOES_BEGIN: 'GET_SHOES_BEGIN',
  GET_SHOES_SUCCESS: 'GET_SHOES_SUCCESS',
  GET_SHOES_ERROR: 'GET_SHOES_ERROR',
};


export class ShoeModel extends Model {

  static columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "brand",
      label: "Brand",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "archived",
      label: "Archived",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (v) => v ? 'True' : 'False',
      },
    },
    {
      name: "stability",
      label: "Stability",
      options: {
        filter: true,
        sort: true,
      }
    },
  ];

  static create_validation = Joi.object({
    name: Joi.string().min(3).max(50).required(),
    stability: Joi.string().required(),
    brand: Joi.string().required(),
    archived: Joi.bool().default(false)
  }).options({ stripUnknown: true, abortEarly: false});

  static edit_validation = Joi.object({
    name: Joi.string().min(3).max(50).required(),
    stability: Joi.string().required(),
    brand: Joi.string().required(),
    archived: Joi.bool().default(false)
  }).options({ stripUnknown: true, abortEarly: false});

  static get_all_shoes() {
    ReduxStore.dispatch({type: actionTypes.GET_SHOES_BEGIN});

    firebase
      .firestore()
      .collection('shoes')
      .get()
      .then((documentSnapshots) => {

        const items = documentSnapshots.docs.map((doc) => {
          const docData = doc.data();
          const item_data = {
            id: doc.id
          };

          ShoeModel.columns.forEach((column) => {
            item_data[column.name] = docData[column.name] ;//? docData[column.name] : '';
          });
          return item_data;
        });

        ReduxStore.dispatch({
          type: actionTypes.GET_SHOES_SUCCESS,
          payload: { items }
        });
      })
      .catch((error) => {
        console.error("Shoes Failed", error);
        ReduxStore.dispatch({
          type: actionTypes.GET_SHOES_ERROR,
          payload: { error }
        });
      });
  }

  static create_shoe(shoe_data) {
    return new Promise((resolve, reject) => {

      const {value, error} = ShoeModel.create_validation.validate(shoe_data);

      if (error)
        return reject(this.formatError(error));

      return firebase
        .firestore()
        .collection('shoes')
        .add(value)
        .then((shoeDoc) => {
          console.log('Created shoe', shoeDoc.id, shoeDoc);
          ShoeModel.get_all_shoes();
          resolve({
            id: shoeDoc.id
          });
        })
        .catch((error) => {
          console.error('Creating shoe failed!', error);
          reject(error);
        });
    });
  }

  static update_shoe_by_id(shoe_id, shoe_data) {
    return new Promise((resolve, reject) => {

      const {value, error} = ShoeModel.edit_validation.validate(shoe_data);

      if (error)
        return reject(this.formatError(error));

      firebase
        .firestore()
        .collection('shoes')
        .doc(shoe_id)
        .update(value)
        .then(() => {
          ShoeModel.get_all_shoes();
          resolve();
        })
        .catch((error) => {
          reject({});
          console.error(error);
        });
    });
  }

  static delete_shoe_by_id(shoe_id) {
    firebase
      .firestore()
      .collection('shoes')
      .doc(shoe_id)
      .update({
        deleted: true
      })
      .then(() => {
        console.log('Deleted shoe by id', shoe_id);
        ShoeModel.get_all_shoes();
      })
      .catch((error) => {
        console.error('Deleting shoe by id failed!', shoe_id, error);
      });
  }

  static update_global_shoes_visibility(hidden_shoes) {

    firebase
      .firestore()
      .collection('shoes')
      .get()
      .then(snapshot => {
        let batch = firebase.firestore().batch();

        snapshot.docs.forEach(doc => {
          batch.update(doc.ref, {hidden: !!hidden_shoes[doc.id]});
        });

        batch
          .commit()
          .then(() => {

          })
          .catch(err => console.error(err));
      })
      .catch(error => console.error(error));

  }

}
