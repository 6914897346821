import Model, {baseActionTypes} from "./base";
import Joi from '@hapi/joi';
import axios from 'axios';
import firebase from "firebase";
import {ReduxStore} from './redux';
import config from "../config";
import auth from "./auth";


const initialState = { };

export const storeUsersReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_STORE_USERS_SUCCESS:
      return {
        ...state,
        [action.payload.store_id]: action.payload.users
      };

    default:
      return state;
  }
};


export const actionTypes = {
  GET_STORE_USERS_SUCCESS: 'GET_STORE_USERS_SUCCESS'
};


export class StoreUserModel extends Model {

  static columns = [
    {
      name: "first_name",
      label: "First Name",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "last_login",
      label: "Last Login",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "updated_at",
      label: "Updated At",
      options: {
        display: false,
        filter: true,
        sort: true,
      }
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        display: false,
        filter: true,
        sort: true,
      }
    },
  ];

  static create_validation = Joi.object({
    first_name: Joi.string().min(2).max(50).required(),
    last_name: Joi.string().min(2).max(50).required(),
    email: Joi.string().email({tlds: false}).required(),
  }).options({ stripUnknown: true, abortEarly: false});

  static edit_validation = Joi.object({
    first_name: Joi.string().min(2).max(50).required(),
    last_name: Joi.string().min(2).max(50).required(),
    // email: Joi.string().email({tlds: false}).allow(''),
  }).options({ stripUnknown: true, abortEarly: false});

  static get_store_users(store_id) {

    firebase
      .firestore()
      .collection('users')
      .where(`stores.${store_id}`, '>', {})
      // .where('deleted', '==', false)
      .onSnapshot((docSnapshots) => {
        const payload = {
          users: docSnapshots.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            last_login: StoreUserModel.format_firestore_timestamp(doc.data().last_login),
            created_at: StoreUserModel.format_firestore_timestamp(doc.data().created_at),
            updated_at: StoreUserModel.format_firestore_timestamp(doc.data().updated_at),
          })),
          store_id
        };
        ReduxStore.dispatch({type: actionTypes.GET_STORE_USERS_SUCCESS, payload});
      });
  }

  static create_store_user(store_id, user_data, roles) {
    return new Promise((resolve, reject) => {

      const {value, error} = StoreUserModel.create_validation.validate(user_data);

      if (error)
        return reject(this.formatError(error));

      auth
        .get_auth_token()
        .then((token) => {

          axios
            .post(config.api_base_url + 'users', {}, {
              params: value,
              headers: {authorization: `Bearer ${token}`}
            })
            .then((res) => {
              const user_id = res.data.uid;

              StoreUserModel
                .update_store_user(store_id, user_id, user_data, roles)
                .then(resolve)
                .catch(reject);
            })
            .catch((error) => {
              ReduxStore.dispatch({
                type: baseActionTypes.API_ERROR,
                error
              });
              reject({});
            });
        });
    });
  }

  static update_store_user(store_id, user_id, user_data, roles) {
    return new Promise((resolve, reject) => {
      const {value, error} = StoreUserModel.edit_validation.validate(user_data);

      if (error)
        return reject(StoreUserModel.formatError(error));

      auth
        .get_auth_token()
        .then((token) => {
          // Update User Roles in this store
          // User is created or already exists. Update the role selections
          axios
            .patch(config.api_base_url + 'store/users', {}, {
              params: {user_id, store_id, ...value, roles},
              headers: {authorization: `Bearer ${token}`}
            })
            .then((res) => {
              resolve();
            })
            .catch(reject);
        });
    });
  }

  static delete_store_user(store_id, user_id) {
    return new Promise((resolve, reject) => {
      if (!store_id || !user_id)
        return reject();

      auth
        .get_auth_token()
        .then((token) => {
          axios
            .delete(config.api_base_url + 'store/users', {
              params: {user_id, store_id},
              headers: {authorization: `Bearer ${token}`}
            })
            .then(() => {
              resolve();
            })
            .catch(reject);
        });
    });
  }

}
