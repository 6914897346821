import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Grid, TextField, Button} from '@material-ui/core';
import {StoreLocationsModel} from "../../models/store_locations";


const StoreProfileForm = (props) => {

  const [form_data, set_form_data] = useState(props.store_location ? {...props.store_location} : {});

  const [form_validation, set_form_validation] = useState({});

  const handleFormChange = useCallback((data) => {
    set_form_data({
      ...form_data,
      ...data
    });
  }, [form_data, set_form_data]);

  const is_edit_mode = !!props.store_location;

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    set_form_validation({});

    if(is_edit_mode) {
      StoreLocationsModel
        .update_store_location_by_location_id(props.store_id, form_data.id, form_data)
        .then(() => {
          props.closeForm()
        })
        .catch(error => set_form_validation(error));
    }
    else {
      StoreLocationsModel
        .create_store_location_by_store_id(props.store_id, form_data)
        .then(() => {
          props.closeForm()
        })
        .catch(error => set_form_validation(error));
    }

  }, [form_data]);

  return (
    <form method="post" noValidate onSubmit={handleSubmit} style={{width: '100%'}}>

      <Grid container spacing={1}>
        <Grid container item xs={12} sm={6} md={5} lg={4} >
          <TextField label="Name" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     // disabled={is_edit_mode}
                     value={form_data.name || ''} fullWidth
                     onChange={e => handleFormChange({name: e.target.value})}
                     error={!!form_validation.name}
                     helperText={form_validation.name} />
        </Grid>
        <Grid container item xs={12} sm={6} md={5} lg={4} >
          <TextField label="Street Address" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     // disabled={is_edit_mode}
                     value={form_data.street_address || ''} fullWidth
                     onChange={e => handleFormChange({street_address: e.target.value})}
                     error={!!form_validation.street_address}
                     helperText={form_validation.street_address} />
        </Grid>
        <Grid container item xs={12} sm={6} md={5} lg={4} >
          <TextField label="City" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     // disabled={is_edit_mode}
                     value={form_data.city || ''} fullWidth
                     onChange={e => handleFormChange({city: e.target.value})}
                     error={!!form_validation.city}
                     helperText={form_validation.city} />
        </Grid>
        <Grid container item xs={12} sm={6} md={5} lg={4} >
          <TextField label="State" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     // disabled={is_edit_mode}
                     value={form_data.state || ''} fullWidth
                     onChange={e => handleFormChange({state: e.target.value})}
                     error={!!form_validation.state}
                     helperText={form_validation.state} />
        </Grid>
        <Grid container item xs={12} sm={6} md={5} lg={4} >
          <TextField label="Country" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     // disabled={is_edit_mode}
                     value={form_data.country || ''} fullWidth
                     onChange={e => handleFormChange({country: e.target.value})}
                     error={!!form_validation.country}
                     helperText={form_validation.country} />
        </Grid>
        <Grid container item xs={12} sm={6} md={5} lg={4} >
          <TextField label="Zip Code" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     // disabled={is_edit_mode}
                     value={form_data.zipcode || ''} fullWidth
                     onChange={e => handleFormChange({zipcode: e.target.value})}
                     error={!!form_validation.zipcode}
                     helperText={form_validation.zipcode} />
        </Grid>
        <Grid container item xs={12} sm={6} md={5} lg={4} >
          <TextField label="Sq ft" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     value={form_data.sq_ft || ''} fullWidth
                     onChange={e => handleFormChange({sq_ft: e.target.value})}
                     error={!!form_validation.sq_ft}
                     helperText={form_validation.sq_ft} />
        </Grid>
        <Grid container item xs={12} >
          <Button color="primary" size='small' margin="normal"
                  type='submit' variant='contained' style={{margin: '10px 10px 30px 0'}}>
            {props.store_location ? 'Update' : 'Create'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

StoreProfileForm.defaultProps = {
  closeForm: () => {}
};

StoreProfileForm.propTypes = {
  store_id: PropTypes.string.isRequired,
  store_location: PropTypes.object,
  closeForm: PropTypes.func,
};

export default StoreProfileForm;
