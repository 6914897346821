import React, {useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import config from "./../config";
import Role from "../models/role";
import PropTypes from 'prop-types';
import NoAccessPage from "../containers/no_access/no_access";


// This component checks if user is authenticated before showing the child component
// Also, if user does not have the right role to visit a page shows the no access page
const PrivateRoute = (props) => {
  // useEffect(() => {
  //   console.log('New PrivateRoute Component was created!');
  // }, []);
  // console.log('Rerendered PrivateRoute Component!', props);

  useEffect(() => {
    if(!props.role.current_role_type && props.auth)
      Role.switch_role_type();
  }, [props.auth.fitted, props.auth.stores]);

  if(!props.capability)
    return <Route exact={props.exact} path={props.path}
                  render={ (p) => <props.component {...p} /> } />;

  if(!props.auth || !props.auth.uid)
    return <Redirect to={config.routes.login} />;
  else if(!props.role.current_role_type)
    return <div>{/* TODO: Add a loading here */}</div>;
  else if(Role.user_has_capability(props.capability))
    return <Route exact={props.exact} path={props.path} render={ (p) => <props.component {...p} /> } />;
  else
    return <NoAccessPage path={props.path} capability={props.capability} />;
};

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.func.isRequired,
  ]),
  capability: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.role,
});

export default connect(mapStateToProps)(PrivateRoute);
