import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {withRouter} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {AppBar, MenuItem, Typography, InputLabel, Select, FormControl,
  Toolbar, IconButton} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Sidebar from "../../components/sidebar/sidebar";
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";
import {RoleTypes} from "../../models/role";
import Role from "../../models/role";
import config from "../../config";


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
    color: '#444',
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '& .MuiToolbar-regular': {
      minHeight: '64px'
    }
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    // marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: '80px 20px 15px',
    overflow: 'hidden',
    minHeight: '100vh',
    boxSizing: 'border-box',
    position: 'relative'
  }
}));

const MasterLayout = (props) => {
  const classes = useStyles();
  const [is_sidebar_open, set_is_sidebar_open] = useState(false);
  const [role_types, set_role_types] = useState([]);

  useEffect(()=> {
    if (props.auth) {
      const types = Object
        .keys(RoleTypes)
        .filter(type => !!props.auth[type])
        .map(type => ({name: type, id: type}));
      set_role_types(types);
    }
  }, [props.auth.fitted, props.auth.stores]);


  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: is_sidebar_open
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => {set_is_sidebar_open(true)}}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: is_sidebar_open
            })}
          >
            <MenuIcon />
          </IconButton>
          {
            props.app_bar ||
            <div style={{ flex: '1', padding: '0 0 0 20px' }}>
              <Typography variant="h6" noWrap>
                {props.title}
              </Typography>
            </div>
          }
          {
            role_types.length <= 1 ? null :
            <div style={{
              flex: '1',
              padding: '0 0 0 20px',
              maxWidth: '180px',
              display: 'flex',
              flexWrap: 'nowrap',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}>
              <FormControl size='small' style={{width: '100%'}}>
                <Select
                  variant='outlined'
                  style={{width: '100%', textTransform: 'capitalize'}}
                  value={props.role.current_role_type}
                  onChange={(e) => {
                    Role.switch_role_type(e.target.value);
                    props.history.push(config.routes.base);
                  }}
                >
                {
                  role_types.map((role, i) => (
                    <MenuItem key={i} value={role.id}
                              style={{textTransform: 'capitalize'}}>{role.name}</MenuItem>
                  ))
                }
                </Select>
              </FormControl>
            </div>
          }

        </Toolbar>
      </AppBar>

      {/*{*/}
        {/*Role.is_fitted_admin() &&*/}
        <Sidebar open={is_sidebar_open} onClose={()=>{set_is_sidebar_open(false)}} />
      {/*}*/}

      <main className={classes.content}>
        {props.children}
      </main>
    </div>
  );
};

MasterLayout.propType = {
  app_bar: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.role,
});

export default withRouter(connect(mapStateToProps)(MasterLayout));
