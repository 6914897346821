import React from 'react';
import {connect} from "react-redux";
import MasterLayout from "../layouts/master";
import Role from "../../models/role";
import StoreProfile from "../common/store_profile";
import {StoreModel} from "../../models/store";


const StoreProfilePage = (props) => {
  return (
    <MasterLayout title='Store Profile'>
      {
        <StoreProfile store={StoreModel.get_current_store()}
          can_edit={Role.user_has_capability(Role.capabilities.update_own_store_profiles)} />
      }
    </MasterLayout>
  );
};

StoreProfilePage.propTypes = {};

const mapStateToProps = state => ({
  auth: state.auth,
  stores: state.stores
});

export default connect(mapStateToProps)(StoreProfilePage);
