import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Grid, TextField, Button} from '@material-ui/core';
import {FittedUserModel} from "../../models/fitted_users";
import TreeViewSelections from '../../components/tree_selector/tree_selector';
import Role from "../../models/role";


const FittedUserForm = (props) => {

  const [form_data, set_form_data] = useState(props.user ? {...props.user} : {});
  const [selected_capabilities, set_selected_capabilities] = useState({});
  const [form_validation, set_form_validation] = useState({});

  useEffect(() => {
    set_selected_capabilities({
      ...selected_capabilities,
      ...(props.user && props.user.fitted ? props.user.fitted.capabilities : {})
    });
  }, [props.user]);

  const handleFormChange = useCallback((data) => {
    set_form_data({
      ...form_data,
      ...data
    });
  }, [form_data, set_form_data]);

  const handle_submit = useCallback((e) => {
    e.preventDefault();
    set_form_validation({});

    if(props.user) {
      FittedUserModel
        .update_fitted_user(form_data.id, form_data, Object.keys(selected_capabilities))
        .catch(set_form_validation);
    }
    else {
      FittedUserModel
        .create_fitted_user(form_data, selected_capabilities)
        .then(props.onClose())
        .catch(set_form_validation);
    }

  }, [form_data, selected_capabilities]);

  return (
    <form method="post" noValidate onSubmit={handle_submit} style={{width: '100%'}}>
      <Grid container spacing={1}>
        <Grid container item xs={12} sm={6} >
          <TextField label="First Name" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     value={form_data.first_name || ''} fullWidth
                     onChange={e => handleFormChange({first_name: e.target.value})}
                     error={!!form_validation.first_name}
                     helperText={form_validation.first_name} />
        </Grid>
        <Grid container item xs={12} sm={6} >
          <TextField label="Last Name" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     value={form_data.last_name || ''} fullWidth
                     onChange={e => handleFormChange({last_name: e.target.value})}
                     error={!!form_validation.last_name}
                     helperText={form_validation.last_name} />
        </Grid>
        <Grid container item xs={12} sm={6} >
          <TextField label="Email" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     value={form_data.email || ''} fullWidth
                     onChange={e => handleFormChange({email: e.target.value})}
                     error={!!form_validation.email}
                     helperText={form_validation.email} />
        </Grid>
        <Grid container item xs={12} >
          <TreeViewSelections data={Role.fitted_user_capabilities}
                              selections={selected_capabilities}
                              onChange={set_selected_capabilities} />
        </Grid>
        <Grid container item xs={12} >
          <Button color="primary" size='small' margin="normal"
                  type='submit' variant='contained' style={{margin: '10px 10px 30px 0'}}>
            {props.user ? 'Update' : 'Create'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

FittedUserForm.defaultProps = {
  onClose: () => {},
  user: null
};

FittedUserForm.propTypes = {
  user: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps)(FittedUserForm);
