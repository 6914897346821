import React from 'react';
import firebase from "firebase";
import Model from "./base";
import {ReduxStore} from "./redux";
import orderBy from "lodash/orderBy";


const initialState = { };

export const storeReportsReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_STORE_CUSTOM_CHARTS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};


export const actionTypes = {
  GET_STORE_CUSTOM_CHARTS: 'GET_STORE_CUSTOM_CHARTS',
};


export class StoreReportModel extends Model {

  static get_store_custom_charts(store_id) {
    firebase
      .firestore()
      .collection("stores")
      .doc(store_id)
      .collection("charts")
      .get()
      .then((chart_docs) => {
        const charts = chart_docs.docs.map((doc) => ({
          id: doc.id,
          ...(doc.data())
        }));
        ReduxStore.dispatch({
          type: actionTypes.GET_STORE_CUSTOM_CHARTS,
          payload: {[store_id]: charts}
        });
      });
  }

  static create_store_custom_chart(store_id, chart_data) {
    return firebase
      .firestore()
      .collection("stores")
      .doc(store_id)
      .collection("charts")
      .add({
        deleted: false,
        editable: true,
        ...chart_data,
        created_at: firebase.firestore.Timestamp.now(),
        updated_at: firebase.firestore.Timestamp.now(),
      });
  }

  static update_store_custom_chart(store_id, chart_id, chart_data) {
    return firebase
      .firestore()
      .collection("stores")
      .doc(store_id)
      .collection("charts")
      .doc(chart_id)
      .update({
        ...chart_data,
        updated_at: firebase.firestore.Timestamp.now(),
      });
  }

  static update_store_custom_chart_orders(store_id, chart_orders = []) {
    if(!store_id)
      return Promise.reject('No store_id was provided!');

    const batch = firebase.firestore().batch();
    const ref = firebase.firestore()
      .collection('stores')
      .doc(store_id).collection('charts');

    chart_orders.forEach((chart) => {
      batch.update(ref.doc(chart.id), { order: chart.order || 100 });
    });

    return batch.commit();
  }

  static delete_store_custom_chart(store_id, chart_id) {
    return firebase
      .firestore()
      .collection("stores")
      .doc(store_id)
      .collection("charts")
      .doc(chart_id)
      .update({
        deleted: true,
        deleted_at: firebase.firestore.Timestamp.now(),
      });
  }

  static calculate_one_dimensional_chart_data(store_data, config) {

    let data = [];
    const chart_id = config.id;

    if(config.dimension1 && config.dimension1.length >= 2) {
      const [entity, attribute] = config.dimension1;

      if (!store_data.data || !store_data.data[chart_id] ||
        !store_data.data[chart_id][entity] || !store_data.data[chart_id][entity][attribute]) {
        return {data};
      }

      let arr = Object
        .keys(store_data.data[chart_id][entity][attribute])
        .map((key) => ({id: key, ...(store_data.data[chart_id][entity][attribute][key])}));

      arr = StoreReportModel.apply_order_limit(arr, config.dimension1_order, config.dimension1_limit);

      data = arr
        .map((obj) => ({
          label: obj.id, value: obj.__total
        }));
    }

    return { data };

  }

  static calculate_two_dimensional_chart_data(store_data, config, reverse) {

    const chart_id = config.id;
    let categories = [], dataset = [];

    if(config.dimension1 && config.dimension1.length >= 2 &&
       config.dimension2 && config.dimension2.length >= 2) {

      const [entity1, attribute1, feature1] = config.dimension1;
      const [entity2, attribute2, feature2] = config.dimension2;

      if (!store_data.data || !store_data.data[chart_id] ||
        !store_data.data[chart_id][entity1] || !store_data.data[chart_id][entity1][attribute1])
        return {};

      const dim1_data = feature1 ? store_data.data[chart_id][entity1][attribute1][feature1] :
        store_data.data[chart_id][entity1][attribute1];

      if(!dim1_data) {
        console.error('Missing dimension data:', store_data.data, chart_id, entity1, attribute1);
        return {data: []};
      }

      let key1 = feature1 ? `${entity1}_${attribute1}_${feature1}` : `${entity1}_${attribute1}`;
      let dim1_keys = Object.keys(store_data.data[key1] || {});

      let key2 = feature2 ? `${entity2}_${attribute2}_${feature2}` : `${entity2}_${attribute2}`;
      let dim2_keys = Object.keys(store_data.data[key2] || {});

      const dimension1_order = reverse ? config.dimension2_order : config.dimension1_order;
      const dimension2_order = reverse ? config.dimension1_order : config.dimension2_order;
      const dimension1_limit = reverse ? config.dimension2_limit : config.dimension1_limit;
      const dimension2_limit = reverse ? config.dimension1_limit : config.dimension2_limit;

      if(!reverse) {
        dataset = dim2_keys
          .map((dim2_key) => {
            let __total = 0;

            let data = dim1_keys.map(dim1_key => {
              const dim2_data = feature2 ? dim1_data[dim1_key][entity2][attribute2][feature2] :
                dim1_data[dim1_key][entity2][attribute2];
              const value = dim2_data[dim2_key] ? dim2_data[dim2_key].__total : 0;
              __total += value;
              return {value, label: dim1_key};
            });

            data = StoreReportModel.apply_order_limit(data, dimension1_order, dimension1_limit, 'value');

            return {seriesname: dim2_key, data, value: __total}
          });

        dataset = StoreReportModel.apply_order_limit(dataset, dimension2_order, dimension2_limit, 'value');
      }
      else {
        dataset = dim1_keys
          .map((dim1_key) => {
            let __total = 0;

            let data = dim2_keys.map(dim2_key => {
              const dim2_data = feature2 ? dim1_data[dim1_key][entity2][attribute2][feature2] :
                dim1_data[dim1_key][entity2][attribute2];
              const value = dim2_data[dim2_key] ? dim2_data[dim2_key].__total : 0;
              __total += value;
              return {value, label: dim2_key};
            });

            data = StoreReportModel.apply_order_limit(data, dimension1_order, dimension1_limit, 'value');

            data = orderBy(data, 'label', 'asc');

            return {seriesname: dim1_key, data, value: __total}
          });

        dataset = StoreReportModel.apply_order_limit(dataset, dimension2_order, dimension2_limit, 'value');

        dataset = orderBy(dataset, 'seriesname', 'asc');
      }

      categories = [{
        "category": (dataset.length ? dataset[0].data.map((obj) => ({ label: obj.label })) : [])
      }];

    }

    return { categories, dataset };
  }

  static calculate_multi_one_dimensional_chart_data(store_data, config, reverse) {

    let data = [];
    const chart_id = config.id;

    if(config.dimension1 && config.dimension1.length >= 2 &&
      config.dimension2 && config.dimension2.length >= 2) {

      const [entity1, attribute1, feature1] = config.dimension1;
      const [entity2, attribute2, feature2] = config.dimension2;

      if (!store_data.data || !store_data.data[chart_id] || !store_data.data[chart_id][entity1] ||
        !store_data.data[chart_id][entity1][attribute1]) {
        return {data: []};
      }

      const dim1_data = feature1 ? store_data.data[chart_id][entity1][attribute1][feature1] :
        store_data.data[chart_id][entity1][attribute1];

      if(!dim1_data) {
        console.error('Missing dimension data:', store_data.data, chart_id, entity1, attribute1);
        return {data: []};
      }

      let key1 = feature1 ? `${entity1}_${attribute1}_${feature1}` : `${entity1}_${attribute1}`;
      let dim1_keys = Object.keys(store_data.data[key1] || {});

      let key2 = feature2 ? `${entity2}_${attribute2}_${feature2}` : `${entity2}_${attribute2}`;
      let dim2_keys = Object.keys(store_data.data[key2] || {});

      const dimension1_order = reverse ? config.dimension2_order : config.dimension1_order;
      const dimension2_order = reverse ? config.dimension1_order : config.dimension2_order;
      const dimension1_limit = reverse ? config.dimension2_limit : config.dimension1_limit;
      const dimension2_limit = reverse ? config.dimension1_limit : config.dimension2_limit;

      if(!reverse) {
        data = dim2_keys
          .map(dim2_key => {
            let total_sum = 0;
            let arr = dim1_keys
              .map((dim1_key) => {
                const dim2_data = feature2 ? dim1_data[dim1_key][entity2][attribute2][feature2] :
                  dim1_data[dim1_key][entity2][attribute2];
                const value = dim2_data[dim2_key] ? dim2_data[dim2_key].__total : 0;
                total_sum += value;
                return {
                  label: dim1_key,
                  value: value,
                }
              });
            arr = StoreReportModel.apply_order_limit(arr, dimension1_order, dimension1_limit, 'value');
            return {title: dim2_key, data: arr, value: total_sum};
          });
        data = StoreReportModel.apply_order_limit(data, dimension2_order, dimension2_limit, 'value');
      }
      else {
        data = dim1_keys
          .map(dim1_key => {
            let total_sum = 0;
            let arr = dim2_keys
              .map((dim2_key) => {
                const dim2_data = feature2 ? dim1_data[dim1_key][entity2][attribute2][feature2] :
                  dim1_data[dim1_key][entity2][attribute2];
                const value = dim2_data[dim2_key] ? dim2_data[dim2_key].__total : 0;
                total_sum += value;
                return {
                  label: dim2_key,
                  value: value,
                }
              });
            arr = StoreReportModel.apply_order_limit(arr, dimension1_order, dimension1_limit, 'value');
            return {title: dim1_key, data: arr, value: total_sum};
          });
        data = StoreReportModel.apply_order_limit(data, dimension2_order, dimension2_limit, 'value');
      }

    }

    return { data };
  }

  static apply_order_limit(arr, order, limit, field = '__total') {
    if(order || limit)
      arr = orderBy(arr, field, order || 'desc');

    if (limit && Number.isInteger(limit))
      arr = arr.splice(0, limit);

    return arr;
  }
}
