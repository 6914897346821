import Model, {baseActionTypes} from "./base";
import Joi from '@hapi/joi';
import axios from 'axios';
import firebase from "firebase";
import {ReduxStore} from './redux';
import config from "../config";
import auth from "./auth";


const initialState = {
  items: null,
};


export const fittedUsersReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_FITTED_USERS_SUCCESS:
      return {
        ...state,
        items: action.payload
      };

    default:
      return state;
  }
};


export const actionTypes = {
  GET_FITTED_USERS_SUCCESS: 'GET_FITTED_USERS_SUCCESS',
};


export class FittedUserModel extends Model {

  static columns = [
    {
      name: "first_name",
      label: "First Name",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "last_login",
      label: "Last Login",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "updated_at",
      label: "Updated At",
      options: {
        display: false,
        filter: true,
        sort: true,
      }
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        display: false,
        filter: true,
        sort: true,
      }
    },
  ];

  static create_schema = Joi.object({
    first_name: Joi.string().min(1).max(50).required(),
    last_name: Joi.string().min(1).max(50).required(),
    email: Joi.string().email({tlds: false}).required(),
  }).options({ stripUnknown: true, abortEarly: false});

  static edit_schema = Joi.object({
    first_name: Joi.string().min(1).max(50).required(),
    last_name: Joi.string().min(1).max(50).required(),
  }).options({ stripUnknown: true, abortEarly: false});

  static get_fitted_users() {
    firebase
      .firestore()
      .collection('users')
      .where('fitted', '>', {})
      .where('deleted', '==', false)
      .onSnapshot((docSnapshots) => {
        const payload = docSnapshots.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          last_login: FittedUserModel.format_firestore_timestamp(doc.data().last_login),
          created_at: FittedUserModel.format_firestore_timestamp(doc.data().created_at),
          updated_at: FittedUserModel.format_firestore_timestamp(doc.data().updated_at),
        }));
        ReduxStore.dispatch({type: actionTypes.GET_FITTED_USERS_SUCCESS, payload});
      });
  }

  static create_fitted_user(user_data, capabilities) {
    return new Promise((resolve, reject) => {
      const {value, error} = FittedUserModel.create_schema.validate(user_data);
      if (error)
        return reject(FittedUserModel.formatError(error));

      auth
        .get_auth_token()
        .then((token) => {
          axios
            .post(config.api_base_url + 'users', {}, {
              params: value,
              headers: {authorization: `Bearer ${token}`}
            })
            .then((res) => {
              const user_id = res.data.uid;

              FittedUserModel
                .update_fitted_user(user_id, user_data, Object.keys(capabilities))
                .then(resolve)
                .catch(reject);
            })
            .catch((error) => {
              ReduxStore.dispatch({
                type: baseActionTypes.API_ERROR,
                error
              });
              reject({});
            });
        });
    });
  }

  static update_fitted_user(user_id, user_data, capabilities) {
    return new Promise((resolve, reject) => {
      const {value, error} = FittedUserModel.edit_schema.validate(user_data);

      if (error)
        return reject(FittedUserModel.formatError(error));

      auth
        .get_auth_token()
        .then((token) => {
          // Update User Roles in this store
          // User is created or already exists. Update the role selections
          axios
            .patch(config.api_base_url + 'fitted/users', {}, {
              params: {user_id, ...value, capabilities},
              headers: {authorization: `Bearer ${token}`}
            })
            .then(resolve)
            .catch(reject);
        });
    });
  }

  static delete_fitted_user(user_id) {
    return firebase
      .firestore()
      .collection('users')
      .doc(user_id)
      .update({
        deleted: true,
        deleted_at: firebase.firestore.Timestamp.now(),
      })
      .catch(error => {
        ReduxStore.dispatch({
          type: baseActionTypes.FIRE_STORE_ERROR,
          error
        });
      });
  }

}
