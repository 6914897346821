import React from 'react';
import {connect} from "react-redux";
import MasterLayout from "../layouts/master";
import Role from "../../models/role";
import StoreEmployees from "../common/store_employees";


const StoreEmployeesPage = (props) => {
  return (
    <MasterLayout title='Store Fitters'>
      {
        props.stores.current_store_id &&
        <StoreEmployees store_id={props.stores.current_store_id}
                        can_create={Role.user_has_capability(Role.capabilities.create_own_employees)}
                        can_update={Role.user_has_capability(Role.capabilities.update_own_employees)}
                        can_delete={Role.user_has_capability(Role.capabilities.delete_own_employees)} />
      }
    </MasterLayout>
  );
};

StoreEmployeesPage.propTypes = {};

const mapStateToProps = state => ({
  stores: state.stores
});

export default connect(mapStateToProps)(StoreEmployeesPage);
