import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Grid, Button} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import DynamicChartEditor from "./store_dynamic_chart_editor";
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import StoreDynamicChartContainer from "./store_dynamic_chart_container";
import Utils from "../../../models/base";
import update from 'immutability-helper';
import {StoreReportModel} from "../../../models/store_report";


const useStyles = makeStyles(() => ({
  chart_container_list: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  new_chart_container: {
    width: '100%',
    height: '100%',
    padding: 4,
  },
  new_chart_button: {
    backgroundColor: '#efefef5c',
    color: '#8d8d8d',
    boxShadow: 'none',
    width: '100%',
    height: '100%',

    '&:hover': {
      backgroundColor: '#efefef',
      color: '#ababab',
    }
  }
}));

const StoreDynamicCharts = (props) => {

  const {store_id, store_data, chart_layout} = props;

  const classes = useStyles();
  const [create_mode, set_create_mode] = useState(false);
  const [charts, set_charts] = useState([]);

  useEffect(() => {
    set_charts(store_data.charts)
  }, [store_data.charts]);

  const move_chart_container = useCallback((dragIndex, hoverIndex) => {
    const dragObject = charts[dragIndex];
    const new_charts = update(charts, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragObject],
      ],
    });
    new_charts.forEach((o, i) => new_charts[i].order = i + 1);
    set_charts(new_charts);

    const chart_orders = new_charts.map((chart) => ({id: chart.id, order: chart.order}));
    StoreReportModel
      .update_store_custom_chart_orders(store_id, chart_orders);

  }, [charts]);

  if(!store_id || !store_data)
    return <b>Loading ...</b>;

  return (
    <>
      <div className={classes.chart_container_list}>
        <DndProvider backend={Backend}>
          {
            Utils.sort_by(charts, 'order').map((chart_config, i) => (
              <StoreDynamicChartContainer {...props} id={`chart_${i}`}
                                          config={chart_config} index={i} key={i}
                                          on_move={move_chart_container} />
            ))
          }
        </DndProvider>
        <div style={{height: chart_layout.cell_height, width: chart_layout.cell_width}}>
          <div className={classes.new_chart_container}>
            <Button variant="contained" color="primary" size="large" onClick={()=>{set_create_mode(true)}}
                    className={classes.new_chart_button} startIcon={<AddIcon />} >
              Add New Chart
            </Button>
          </div>
        </div>
      </div>


      {
        create_mode &&
        <DynamicChartEditor {...props} config={{}} on_close={() => set_create_mode(false)} />
      }
    </>
  );
};


StoreDynamicCharts.propTypes = {
  store_id: PropTypes.string.isRequired,
  store_data: PropTypes.object.isRequired,
  on_change: PropTypes.func.isRequired,
  chart_layout: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  store_charts: state.store_charts
});

export default connect(mapStateToProps)(StoreDynamicCharts);
