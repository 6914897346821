import React from 'react';
import PropTypes from 'prop-types';
import ReactFC from 'react-fusioncharts';


const StaticChart = ({id, data, config}) => {
  return (
    <ReactFC
      id={id}
      dataFormat='json'
      width='100%'
      height='100%'
      type={config.type}
      dataSource={{
        chart: {
          caption: config.caption,
          subCaption: config.subCaption,
          xAxisName: config.xAxisName,
          yAxisName: config.yAxisName,
          numberSuffix: config.numberSuffix,
          plottooltext: config.plottooltext,
          showlegend: config.showlegend,
          showValues: config.showValues,
          showAxis: config.showAxis,
          // showyaxisvalues: config.showyaxisvalues,
          theme: "fusion",
          labelDisplay: "rotate",
          slantLabel: "1",
        },
        ...data  // data, dataset, categories
      }}
    />
  );
};

StaticChart.propTypes = {
  data: PropTypes.object.isRequired,
  config: PropTypes.shape({
    type: PropTypes.oneOf(['column2d', 'pie2d', 'stackedcolumn2d']),
    caption: PropTypes.string.isRequired,
    subCaption: PropTypes.string,
    xAxisName: PropTypes.string,
    yAxisName: PropTypes.string,
    numberSuffix: PropTypes.string,
    plottooltext: PropTypes.string,
    showAxis: PropTypes.bool,
    showValues: PropTypes.bool,
    showlegend: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired
      })
    )
  }),
};

export default StaticChart;
