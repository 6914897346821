import Model from "./base";
import firebase from "firebase";
import {ReduxStore} from './redux';
import parallel from 'async/parallel';

const initialState = {
  items: null,
};


export const fittedSurveysReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_FITTED_SURVEYS_SUCCESS:
      return {
        ...state,
        [action.payload.survey_category]: action.payload.survey_data
      };

    case actionTypes.GET_FITTED_SURVEY_QUESTIONS_SUCCESS:
      return {
        ...state,
        [action.payload.survey_category]: {
          ...(state[action.payload.survey_category]),
          questions: [...action.payload.survey_questions]
        }
      };

    case actionTypes.GET_ALL_FITTED_SURVEY_QUESTIONS_SUCCESS:
      // Stores a list of questions of all surveys
      if(action.payload && action.payload.questions && Array.isArray(action.payload.questions))
      {
        const new_state = {...state};
        new_state.all_survey_questions = {};
        action.payload.questions.forEach((question) => {
          new_state.all_survey_questions[question.question_id] = question;
        });
        return new_state;
      }
      return state;
    default:
      return state;
  }
};


export const actionTypes = {
  GET_FITTED_SURVEYS_SUCCESS: 'GET_FITTED_SURVEYS_SUCCESS',
  GET_FITTED_SURVEY_QUESTIONS_SUCCESS: 'GET_FITTED_SURVEY_QUESTIONS_SUCCESS',
  GET_ALL_FITTED_SURVEY_QUESTIONS_SUCCESS: 'GET_ALL_FITTED_SURVEY_QUESTIONS'
};


export class FittedSurveyModel extends Model {

  // static create_schema = Joi.object({
  //   first_name: Joi.string().min(1).max(50).required(),
  //   last_name: Joi.string().min(1).max(50).required(),
  //   email: Joi.string().email({tlds: false}).required(),
  // }).options({ stripUnknown: true, abortEarly: false});
  //
  // static edit_schema = Joi.object({
  //   first_name: Joi.string().min(1).max(50).required(),
  //   last_name: Joi.string().min(1).max(50).required(),
  // }).options({ stripUnknown: true, abortEarly: false});

  static get_fitted_survey_by_category_id(survey_category, fetch_questions = true) {
    firebase
      .firestore()
      .collection('surveys')
      .where('category', '==', survey_category)
      .where('enabled', '==', true)
      .where('deleted', '==', false)
      .get()
      .then((snapshots) => {
        let survey = {};
        if (snapshots.docs.length) {
          const doc = snapshots.docs[0];
          survey = {
            id: doc.id,
            ...doc.data(),
          };

          if(fetch_questions)
            FittedSurveyModel.get_fitted_survey_questions_by_survey_id(doc.id, survey_category);
        }
        ReduxStore.dispatch({
          type: actionTypes.GET_FITTED_SURVEYS_SUCCESS,
          payload: {survey_category, survey_data: survey}
        });
      });
  }

  static get_fitted_survey_questions_by_survey_id(survey_id, survey_category) {
    firebase
      .firestore()
      .collection('surveys')
      .doc(survey_id)
      .collection('questions')
      .where('enabled', '==', true)
      .where('deleted', '==', false)
      .get()
      .then((snapshots) => {
        const questions = Model.sort_by(
          snapshots.docs.map((doc) => {
            const options = Model.map_to_array(doc.data().options);
            return {
              id: doc.id,
              ...doc.data(),
              options,
            };
          })
        );
        ReduxStore.dispatch({
          type: actionTypes.GET_FITTED_SURVEY_QUESTIONS_SUCCESS,
          payload: {survey_category, survey_questions: questions}
        });
      });
  }


  /**
   * Get a list of all active fitted questions
   * Access the result from state[store_id].all_survey_questions
   */
  static get_all_fitted_survey_questions() {
    firebase
      .firestore()
      .collection('surveys')
      .where('deleted', '==', false)
      .get()
      .then((snapshot) => {
        const questions = [];
        const tasks = snapshot.docs.map((survey_doc) => ((callback) => {
          survey_doc
            .ref
            .collection('questions')
            .where('deleted', '==', false)
            .where('enabled', '==', true)
            .get()
            .then((snapshot) => {
              snapshot.docs.forEach((question_doc) => {
                questions.push({
                  question_id: question_doc.id,
                  survey_id: survey_doc.id,
                  ...(question_doc.data())
                });
              });
              callback();
            });
        }));

        parallel(tasks, () => {
          ReduxStore.dispatch({
            type: actionTypes.GET_ALL_FITTED_SURVEY_QUESTIONS_SUCCESS,
            payload: { questions }
          });
        });
      })
  }

}
