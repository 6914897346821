import React, {useEffect, useState, useCallback} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import DynamicChart from "./store_dynamic_chart";
import PropTypes from 'prop-types';
import auth from "../../../models/auth";
import app_config from "../../../config";
import axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Loader from "../../../components/loader/loader";
import {StoreReportModel} from "../../../models/store_report";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {connect} from "react-redux";
import {StoreSurveysModel} from "../../../models/store_surveys";
import {FittedSurveyModel} from "../../../models/fitted_surveys";
import {withSnackbar} from 'notistack';


const useStyles = makeStyles(() => ({
  chart_settings_form: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    display: 'flex',
    padding: '0 20px 0 10px',
    maxHeight: 500,
    overflow: 'auto'
  },
  dimension_container: {
    flexWrap: 'nowrap',
    flexDirection: 'column',
    display: 'flex',
    background: '#eee',
    margin: '8px 0 4px',
    padding: '8px',
    border: '1px solid #e6e6e6',

    '& h5': {
      margin: 0
    },
    '& a': {
      maxWidth: '100px'
    }
  },
  add_dimension_button: {
    backgroundColor: '#ECF2FF',
    color: '#145EFB',
    margin: '10px 0',
    outline: 'none',
    boxShadow: 'none',
    padding: '14px 0',
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: '#cadbff',
      color: '#145EFB',
      boxShadow: 'none',
    }
  },
  remove_dimension_button: {
    backgroundColor: '#fb2546',
    color: '#ffffff',
    margin: '10px 0',
    outline: 'none',
    boxShadow: 'none',
    padding: '6px 0',
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: '#fb2546',
      color: '#ffffff',
      boxShadow: 'none',
    }
  },
}));

const dimensions = {
  'customer': {
    label: 'Customer',
    children: {
      'age': {label: 'Age'},
      'name': {label: 'Name'},
      'arch_height': {label: 'Arch Height'},
      'gait': {label: 'Gait'},
      'shoe_size': {label: 'Shoe Size'},
      'shoe_width': {label: 'Shoe Width'},
      'stability': {label: 'Stability'},
    }
  },
  'employee': {
    label: 'Employee',
    children: {
      'name': {label: 'Name'}
    }
  },
  'pulled_shoes': {
    label: 'Pulled Shoes',
    children: {
      'brand': {label: 'Brand'},
      'unique_brand': {label: 'Unique Brand'},
      'name': {label: 'Name'},
      'stability': {label: 'Stability'},
    }
  },
  'purchased_shoes': {
    label: 'Purchased Shoes',
    children: {
      'brand': {label: 'Brand'},
      'unique_brand': {label: 'Unique Brand'},
      'name': {label: 'Name'},
      'stability': {label: 'Stability'},
    }
  },
  'survey': {
    label: 'Survey',
    children: {
      // questions are dynamically added here
    }
  },
};

const default_config = {
  type: 'column2d',
  caption: '',
  subCaption: '',
  xAxisName: '',
  yAxisName: '',
  numberSuffix: '',
  plottooltext: '',
  showAxis: true,
  showValues: false,
  showlegend: true,
  data: [],
};


const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);


const list_2d_charts = ['stackedcolumn2d', 'pie2d'];


const DynamicChartEditor = (props) => {

  const classes = useStyles();
  const [confirm_delete, set_confirm_delete] = useState(false);

  const [config, set_config] = useState({
    ...default_config,
    ...props.config
  });

  const is_2d_chart = list_2d_charts.indexOf(config.type) >= 0;

  const [data, set_data] = useState();

  const update_config = useCallback((obj) => {
    const new_config = {
      ...config,
      ...obj
    };

    if (
      (config.type !== new_config.type) ||
      (config.dimension1 && config.dimension1 !== new_config.dimension1) ||
      (config.dimension2 && config.dimension2 !== new_config.dimension2)
    )
    {
      set_data();
      fetch_data(new_config);
    }

    set_config(new_config);
  }, [config]);

  const update_type = useCallback((type) => {
    if(type === 'stackedcolumn2d')
      update_config({
        type,
        dimension2_limit: null, dimension2_order: null,
        dimension1_limit: null, dimension1_order: null
      });
    else
      update_config({ type });
  }, [update_config]);

  const update_dimension = useCallback((dimension, key, value) => {
    if(key === 'entity') {
      if (value === 'survey')
        update_config({[dimension]: [value, '', '']});
      else
        update_config({[dimension]: [value, '']});
    }
    else if(key === 'attribute') {
      if (config[dimension][0] === 'survey') {
        const survey_id = dimensions['survey'].children[value].survey_id;
        update_config({[dimension]: [config[dimension][0], survey_id, value]});
      }
      else
        update_config({[dimension]: [config[dimension][0], value]});
    }
  }, [config]);

  const fetch_data = useCallback((chart) => {
    auth
      .get_auth_token()
      .then((token) => {
        axios
          .get(app_config.api_base_url + 'report/v2/sample', {
            params: {
              chart: JSON.stringify(chart),
              retailer_id: props.store_id,
            },
            headers: {'authorization': `Bearer ${token}`}
          })
          .then(res => {
            set_data(res.data);
            console.log('Sample Chart Data:', res.data);
          })
      });
  }, [set_data]);

  const save_chart = useCallback(() => {
    StoreReportModel
      .update_store_custom_chart(props.store_id, config.id, config)
      .then(() => {
        props.on_change();
        props.on_close();
      })
      .catch((e) => {
        props.enqueueSnackbar(`Can not save the chart! ${e.message}`, {variant: 'error'});
      });
  }, [props.store_id, props.on_close, config]);

  const create_chart = useCallback(() => {
    StoreReportModel
      .create_store_custom_chart(props.store_id, config)
      .then(() => {
        props.on_change();
        props.on_close();
      })
      .catch((e) => {
        props.enqueueSnackbar(`Can not create the chart! ${e.message}`, {variant: 'error'});
      });
  }, [props.store_id, props.on_close, config]);

  useEffect(() => {
    // If the chart settings is already set (edit mode) preload it with recent data
    if(config.id)
      fetch_data(config);

    if(!props.store_surveys[props.store_id] || !props.store_surveys[props.store_id].all_survey_questions)
      StoreSurveysModel.get_all_store_survey_questions(props.store_id);

    if(!props.fitted_surveys || !props.fitted_surveys.all_survey_questions)
      FittedSurveyModel.get_all_fitted_survey_questions();
  }, []);

  useEffect(() => {
    let store_questions = {}, fitted_questions = {};

    if(props.store_surveys[props.store_id])
      store_questions = props.store_surveys[props.store_id].all_survey_questions;

    if(props.fitted_surveys.all_survey_questions)
      fitted_questions = props.fitted_surveys.all_survey_questions;

    dimensions['survey'].children = {
      ...fitted_questions,
      ...store_questions
    };

  }, [props.store_surveys, props.fitted_surveys]);

  const delete_chart = useCallback(() => {
    set_confirm_delete(false);
    StoreReportModel
      .delete_store_custom_chart(props.store_id, config.id)
      .then(() => {
        props.enqueueSnackbar('Chart is deleted.', {variant: 'success'});
        props.on_close();
        props.on_change();
      })
      .catch(() => {
        props.enqueueSnackbar('Chart delete is unsuccessful.', {variant: 'error'});
      });
  }, [props.store_id, config.id]);

  const remove_2nd_dimension = useCallback(() => {
    update_config({
      dimension2: null,
      dimension2_order: null,
      dimension2_limit: null,
    });
  }, [update_config]);

  return (
    <Dialog fullWidth maxWidth='lg' open onClose={props.on_close}>
      <Grid container direction="row" style={{padding: '0 2px'}}>
        <Grid item xs={6} md={5} lg={4}>
          <form noValidate className={classes.chart_settings_form}>
            <FormControl style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '5px 0 10px'
            }}>
              <h2 style={{margin: 0, lineHeight: '40px'}}>Chart Settings</h2>
              <Tooltip title="Delete this chart" aria-label="Delete this chart">
                <Fab color="secondary" onClick={()=>set_confirm_delete(true)} size='small'>
                  <DeleteIcon />
                </Fab>
              </Tooltip>
            </FormControl>

            <FormControl>
              <TextField label="Caption" size='small' variant="outlined" margin='dense'
                         value={config.caption} onChange={e => update_config({caption: e.target.value})} />
            </FormControl>

            <FormControl>
              <TextField label="Sub Caption" size='small' variant="outlined" margin='dense'
                         value={config.subCaption} onChange={e => update_config({subCaption: e.target.value})} />
            </FormControl>

            <FormControl>
              <TextField select label="Type" variant="outlined" size="small" margin="dense"
                         value={config.type} onChange={e => update_type(e.target.value)}>
                <MenuItem value='line'>Line (1d)</MenuItem>
                <MenuItem value='column2d'>Simple Bar (1d)</MenuItem>
                <MenuItem value="stackedcolumn2d">Stacked Bar (2d)</MenuItem>
                <MenuItem value="pie2d">Pie (1d/2d)</MenuItem>
              </TextField>
            </FormControl>

            <div className={classes.dimension_container}>
              <FormControl>
                <h5>1st Dimension</h5>
                <Grid container direction="row">
                  <Grid item xs={12} md={6} style={{paddingRight: 4}}>
                    <TextField select fullWidth label="Entity" variant="outlined" size="small" margin="dense"
                               value={config.dimension1 && config.dimension1[0]}
                               onChange={e => update_dimension('dimension1', 'entity', e.target.value)}>
                      {
                        Object
                          .keys(dimensions)
                          .map((key, i) => (
                            <MenuItem key={i} value={key}>{dimensions[key].label}</MenuItem>
                          ))
                      }
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6} style={{paddingLeft: 4}}>
                    {
                      config.dimension1 && config.dimension1.length >= 2 && config.dimension1[0] &&
                      <TextField select fullWidth label="Attribute" variant="outlined" size="small" margin="dense"
                                 value={config.dimension1 && (config.dimension1[0] === 'survey' ? config.dimension1[2] : config.dimension1[1])}
                                 onChange={e => update_dimension('dimension1', 'attribute', e.target.value)}>
                        {
                          Object
                            .keys(dimensions[config.dimension1[0]].children)
                            .map((key, i) => (
                              <MenuItem key={i} value={key}>{dimensions[config.dimension1[0]].children[key].label}</MenuItem>
                            ))
                        }
                      </TextField>
                    }
                  </Grid>
                </Grid>
              </FormControl>

              <FormControl>
              {
                config.dimension1_limit ?
                <Grid container direction="row">
                  <Grid item sm={12} md={4} style={{paddingRight: 4}}>
                    <TextField select fullWidth label="Order" variant="outlined" size="small" margin="dense"
                               value={config.dimension1_order || 'desc'}
                               onChange={e => update_config({dimension1_order: e.target.value})}>
                      <MenuItem value='desc'>Top</MenuItem>
                      <MenuItem value='asc'>Bottom</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item sm={12} md={4} style={{paddingLeft: 4}}>
                    <TextField fullWidth label="Limit" variant="outlined" size="small" margin="dense"
                               value={config.dimension1_limit}
                               type="number" InputProps={{ inputProps: { min: 1, max: 100 } }}
                               onChange={e => update_config({dimension1_limit: Number.parseInt(e.target.value)})} />
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <Link href="#" style={{marginTop: 18, display: 'block', textAlign: 'center'}}
                          onClick={()=>update_config({dimension1_limit: null, dimension1_order: null})}>
                      Remove Limit
                    </Link>
                  </Grid>
                </Grid>
                :
                (
                  config.type !== 'stackedcolumn2d' ?
                  <Link href="#" onClick={()=>update_config({dimension1_limit: 5, dimension1_order: 'desc'})}>
                    Add Limit
                  </Link>
                  : null
                )
              }
              </FormControl>
            </div>

            {
              config.dimension2 && is_2d_chart &&
              <div className={classes.dimension_container}>
                <FormControl>
                  <h5>
                    2nd Dimension (<Link onClick={remove_2nd_dimension} href='#'>Remove 2nd Dimension</Link>)
                  </h5>

                  <Grid container direction="row">
                    <Grid item xs={12} md={6} style={{paddingRight: 4}}>
                      <TextField select fullWidth label="Entity" variant="outlined" size="small" margin="dense"
                                 value={config.dimension2 && config.dimension2[0]}
                                 onChange={e => update_dimension('dimension2', 'entity', e.target.value)}>
                        {
                          Object
                            .keys(dimensions)
                            .map((key, i) => (
                              <MenuItem key={i} value={key}>{dimensions[key].label}</MenuItem>
                            ))
                        }
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6} style={{paddingLeft: 4}}>
                      {
                        config.dimension2 && config.dimension2[0] &&
                        <TextField select fullWidth label="Attribute" variant="outlined" size="small" margin="dense"
                                   value={config.dimension2 && (config.dimension2[0] === 'survey' ? config.dimension2[2] : config.dimension2[1])}
                                   onChange={e => update_dimension('dimension2', 'attribute', e.target.value)}>
                          {
                            Object
                              .keys(dimensions[config.dimension2[0]].children)
                              .map((key, i) => (
                                <MenuItem key={i} value={key}>{dimensions[config.dimension2[0]].children[key].label}</MenuItem>
                              ))
                          }
                        </TextField>
                      }
                    </Grid>
                  </Grid>

                  {
                    config.dimension2_limit ?
                      <FormControl>
                        <Grid container direction="row">
                          <Grid item sm={12} md={4} style={{paddingRight: 4}}>
                            <TextField select fullWidth label="Order" variant="outlined" size="small" margin="dense"
                                       value={config.dimension2_order || 'desc'}
                                       onChange={e => update_config({dimension2_order: e.target.value})}>
                              <MenuItem value='desc'>Top</MenuItem>
                              <MenuItem value='asc'>Bottom</MenuItem>
                            </TextField>
                          </Grid>
                          <Grid item sm={12} md={4} style={{paddingLeft: 4}}>
                            <TextField fullWidth label="Limit" variant="outlined" size="small" margin="dense"
                                       value={config.dimension2_limit}
                                       type="number" InputProps={{inputProps: {min: 1, max: 100}}}
                                       onChange={e => update_config({dimension2_limit: Number.parseInt(e.target.value)})}/>
                          </Grid>
                          <Grid item sm={12} md={4}>
                            <Link href="#" style={{marginTop: 18, display: 'block', textAlign: 'center'}}
                                  onClick={()=>update_config({dimension2_limit: null, dimension2_order: null})}>
                              Remove Limit
                            </Link>
                          </Grid>
                        </Grid>
                      </FormControl>
                      :
                      (
                        config.type !== 'stackedcolumn2d' ?
                        <Link href="#" onClick={()=>update_config({dimension2_limit: 5, dimension2_order: 'desc'})}>
                          Add Limit
                        </Link>
                        : null
                      )
                  }
                </FormControl>
                {/*<Button variant="contained" className={classes.remove_dimension_button} startIcon={<RemoveIcon />}*/}
                        {/*onClick={() => update_config({dimension2: null})}>*/}
                  {/*Remove 2nd Dimension*/}
                {/*</Button>*/}
              </div>
            }

            {
              !config.dimension2 && is_2d_chart &&
              <FormControl>
                <Button variant="contained" className={classes.add_dimension_button} startIcon={<AddIcon/>}
                        onClick={() => update_config({dimension2: ['', '']})}>
                  Add 2nd Dimension
                </Button>
              </FormControl>
            }

            <FormControl>
              <HtmlTooltip placement="right"
              title={
                  <div>
                    <p>Available values:</p>
                    <div><b>$label</b></div>
                    <div><b>$value</b></div>
                    <div><b>$seriesName</b></div>
                    <div><b>$percentValue</b></div>
                    <div><b>$dataValue</b></div>
                    <div><b>$sum</b></div>
                  </div>
                }
              >
                <TextField label="Tooltip" size='small' variant="outlined" margin='dense'
                           value={config.plottooltext}
                           onChange={e => update_config({plottooltext: e.target.value})} />
              </HtmlTooltip>
            </FormControl>

            <FormControl>
              <TextField label="X Axis" size='small' variant="outlined" margin='dense'
                         value={config.xAxisName} onChange={e => update_config({xAxisName: e.target.value})} />
            </FormControl>

            <FormControl>
              <TextField label="Y Axis" size='small' variant="outlined" margin='dense'
                         value={config.yAxisName} onChange={e => update_config({yAxisName: e.target.value})} />
            </FormControl>

            <FormControl>
              {
                is_2d_chart &&
                <FormControlLabel label="Show Legend" control={
                  <Switch checked={config.showlegend} value={config.showlegend}
                          onChange={() => update_config({showlegend: !config.showlegend})}/>}/>
              }

              {/*<FormControlLabel label="Show Axis" control={*/}
                {/*<Switch checked={config.showAxis} value={config.showAxis}*/}
                        {/*onChange={() => update_config({showAxis: !config.showAxis})} />} />*/}

              <FormControlLabel label="Show Values" control={
                <Switch checked={config.showValues} value={config.showValues}
                        onChange={() => update_config({showValues: !config.showValues})} />} />

              {
                is_2d_chart &&
                <FormControlLabel label="Show Toggle Button" control={
                  <Switch checked={config.showToggleButton} value={config.showToggleButton}
                          onChange={() => update_config({showToggleButton: !config.showToggleButton})}/>}/>
              }

              <FormControlLabel label="Show On App" control={
                <Switch checked={config.showOnApp} value={config.showOnApp}
                        onChange={() => update_config({showAxis: !config.showOnApp})} />} />
            </FormControl>

            <FormControl>
              <Button variant="contained" color="primary" style={{margin: '18px 0 14px'}}
                      onClick={config.id ? save_chart : create_chart}>
                {config.id ? 'Save' : 'Create'}
              </Button>
            </FormControl>

          </form>
        </Grid>
        <Grid item xs={6} md={7} lg={8} style={{position: 'relative'}}>
          <div style={{height: '100%', maxHeight: '500px', overflow: 'hidden'}}>
          {
            data &&
            <DynamicChart hide_buttons store_data={{data}} on_change={props.on_change}
                          on_close={props.on_close} config={config} store_id={props.store_id}/>
          }
          {
            !data &&
            config.type && config.dimension1 && config.dimension1.length === 2 &&
            config.dimension1[0] && config.dimension1[1] &&
            <Loader />
          }
          </div>
        </Grid>

        <Dialog open={confirm_delete} aria-labelledby="Delete chart"
                onClose={() => set_confirm_delete(false)}>
          <DialogTitle id="alert-dialog-slide-title">
            Are you sure you want to delete this chart?
          </DialogTitle>
          <DialogActions>
            <Button variant='contained' onClick={() => set_confirm_delete(false)} color="primary">
              Cancel
            </Button>
            <Button variant='contained' onClick={delete_chart} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Dialog>
  );
};

DynamicChartEditor.propTypes = {
  store_id: PropTypes.string.isRequired,
  on_change: PropTypes.func.isRequired,
  on_close: PropTypes.func.isRequired,
  config: PropTypes.shape({
    type: PropTypes.oneOf(['column2d', 'pie2d', 'stackedcolumn2d']),
    caption: PropTypes.string,
    subCaption: PropTypes.string,
    xAxisName: PropTypes.string,
    yAxisName: PropTypes.string,
    numberSuffix: PropTypes.string,
    plottooltext: PropTypes.string,
    showAxis: PropTypes.bool,
    showValues: PropTypes.bool,
    showlegend: PropTypes.bool,
    showOnApp: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired
      })
    )
  }),
};

const mapStateToProps = state => ({
  store_surveys: {...state.store_surveys},
  fitted_surveys: {...state.fitted_surveys}
});

export default withSnackbar(connect(mapStateToProps)(DynamicChartEditor));
