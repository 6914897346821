import React from 'react';
import {connect} from "react-redux";
import MasterLayout from "../layouts/master";


const TagsPage = () => {
  return (
    <MasterLayout title='Tags'>

    </MasterLayout>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(TagsPage);
