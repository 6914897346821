import React, { useRef, useState, useCallback } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { ListItem, ListItemText, Collapse, Grid, TextField, IconButton,
  InputLabel, MenuItem, FormControl, Select} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import HeightIcon from '@material-ui/icons/Height';
import DeleteIcon from '@material-ui/icons/Delete';

const ItemTypes = {
  Option: 'question_option',
};

const style = {
  margin: '10px 0',
  cursor: 'ns-resize',
};

const SurveyQuestionOption = ({ id, option, index, onChange, onMove, onDelete }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.Option,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      onMove(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  });
  const [expanded, set_expanded] = useState(false);

  const [{isDragging}, drag] = useDrag({
    item: {type: ItemTypes.Option, id, index},
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));


  return (
    <div ref={ref} style={{...style, opacity}}>
      <ListItem style={{
        padding: 0, backgroundColor: '#f3f3f3', border: '1px solid #ddd',
        borderLeft: (option.enabled? '4px solid #00a307' : '4px solid #f00')
      }}>
        <HeightIcon style={{margin: '0 5px 0 10px'}}/>
        <div onClick={()=>set_expanded(!expanded)}
             style={{flex: 1, textAlign: 'left', backgroundColor: 'transparent',
               display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
          {expanded ? <ExpandLess /> : <ExpandMore />}
          <ListItemText primary={option.label} style={{padding: '0 10px'}}/>
        </div>
        <IconButton color="secondary" aria-label="add an alarm" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit
                style={{border: '2px dashed #eee', padding: '25px', borderTop: 0}} >
        <SurveyQuestionOptionForm option={option} onChange={onChange} />
      </Collapse>
    </div>
  )
};

const SurveyQuestionOptionForm = ({option, onChange}) => {

  // const [form_data, set_form_data] = useState(option ? {...option} : {});
  const [form_validation, set_form_validation] = useState({});

  const handle_form_change = useCallback((data) => {
    onChange({
      ...option,
      ...data
    });
  }, [onChange]);

  return (
    <Grid container spacing={1}>
      <Grid container item xs={12} >
        <TextField label="Label"
                   draggable
                   onDragStart={e => {
                     e.preventDefault();
                     e.stopPropagation();
                   }}
                   type="text"
                   required
                   margin="dense"
                   size="small"
                   variant="outlined"
                   value={option.label || ''} fullWidth
                   onChange={e => handle_form_change({label: e.target.value})}
                   error={!!form_validation.label}
                   helperText={form_validation.label} />
      </Grid>
      <Grid container item xs={12} sm={6} md={4} >
        <FormControl fullWidth variant="outlined" margin="dense" size="small">
          <InputLabel>Status</InputLabel>
          <Select value={option.enabled || false} labelWidth={50}
                  onChange={(e) => handle_form_change({enabled: e.target.value})} >
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SurveyQuestionOption;
