import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Button, CircularProgress, Dialog, DialogTitle, DialogActions,
  DialogContentText, DialogContent
} from '@material-ui/core';

const LoadingButton = (props) => {

  let [is_loading, set_is_loading] = useState(false);
  let [is_confirm_mode, set_is_confirm_mode] = useState(false);

  let onPress = useCallback(() => {
    if(!is_loading) {
      if(props.confirm_message)
        set_is_confirm_mode(true);
      else
        set_is_loading(true);
    }
  }, [is_loading]);

  useEffect(() => {
    if(is_loading)
      props.onPress(() => set_is_loading(false))
  }, [is_loading]);

  return (
    <>
      <Button
        color={props.color || "primary"}
        variant={props.variant || "contained"}
        style={props.buttonStyle}
        disabled={is_loading}
        onClick={onPress}
      >
        {props.icon}
        {props.text}
        {is_loading && <CircularProgress size={24} style={{position: 'absolute', top: 5}} />}
      </Button>
      {
        is_confirm_mode &&
        <Dialog
          open={true}
          onClose={() => {set_is_confirm_mode(false)}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{props.confirm_title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.confirm_message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { set_is_confirm_mode(false) }} color="secondary" variant="contained">
              {props.confirm_reject || 'No'}
            </Button>
            <Button onClick={() => { set_is_loading(true); set_is_confirm_mode(false); }}
                    color="primary" variant="contained" autoFocus>
              {props.confirm_accept || 'Yes'}
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
};

LoadingButton.defaultProps = {
  buttonStyle: {},
  textStyle: {},
  text: '',
  onPress: () => {}
};

LoadingButton.propTypes = {
  variant: PropTypes.string,
  buttonStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  textStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  text: PropTypes.string,
  confirm_title: PropTypes.string,
  confirm_message: PropTypes.string,
  confirm_accept: PropTypes.string,
  confirm_reject: PropTypes.string,
  onPress: PropTypes.func.isRequired,
};

export default LoadingButton;
