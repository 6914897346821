import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import MUIDataTable from "mui-datatables";
import {
  TableRow,
  Chip,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Tooltip,
  IconButton
} from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUserCog} from '@fortawesome/free-solid-svg-icons';
import StoreUserForm from './store_user_form';
import PropTypes from 'prop-types';
import {StoreUserModel} from "../../models/store_users";
import StoreUserRoles from './store_user_roles';
import Role from "../../models/role";
import {StoreUserRoleModel} from "../../models/store_user_roles";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";


const StoreUsers = (props) => {

  const [create_mode, set_create_mode] = useState(false);
  const [roles_mode, set_roles_mode] = useState(false);
  const [roles, set_roles] = useState({});

  const columns = [
    ...StoreUserModel.columns,
    {
      name: "name",
      label: "Roles",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (v, meta) => {
          const user = props.store_users[props.store_id][meta.rowIndex];
          return !user.stores || !user.stores[props.store_id] ?  '-' :
            Object
              .keys(user.stores[props.store_id].roles)
              .map((role_id, i) => (
                <Chip key={i} icon={<SupervisorAccountIcon />} label={roles[role_id]} size="small"
                  style={{margin: '2px', textTransform: 'capitalize'}} />
              ));
        }
      },
    },
  ];

  useEffect(() => {
    if (props.store_id) {
      if (!props.store_user_roles[props.store_id])
        StoreUserRoleModel.get_store_user_roles(props.store_id);
      else {
        const roles = {};
        props.store_user_roles[props.store_id].forEach(role => {
          roles[role.id] = role.label;
        });
        set_roles(roles);
      }
    }
  }, [props.store_user_roles[props.store_id]]);

  useEffect(() => {
    if (props.store_id && !props.store_users[props.store_id])
      StoreUserModel.get_store_users(props.store_id);
  }, [props.store_users.items]);

  if (!props.store_id || !props.store_users[props.store_id])
    return <b>Loading ...</b>;

  return (
    <>
      <Dialog scroll='body' maxWidth='sm' fullWidth open={create_mode}
              onClose={() => set_create_mode(false)}>
        <DialogTitle>Create New Store User</DialogTitle>
        <DialogContent>
          <StoreUserForm store_id={props.store_id}
                         onClose={() => set_create_mode(false)}/>
        </DialogContent>
      </Dialog>

      <Dialog scroll='body' maxWidth='lg' fullWidth open={roles_mode}
              onClose={() => set_roles_mode(false)}>
        <DialogContent>
          <StoreUserRoles store_id={props.store_id}
                          can_create={Role.user_has_capability(Role.capabilities.create_store_user_roles)}
                          can_update={Role.user_has_capability(Role.capabilities.update_store_user_roles)}
                          can_delete={Role.user_has_capability(Role.capabilities.delete_store_user_roles)} />
        </DialogContent>
      </Dialog>

      <MUIDataTable
        title="Store Users"
        data={props.store_users[props.store_id]}
        columns={columns}
        options={{
          filterType: "dropdown",
          responsive: "scrollFullHeight",  // scrollMaxHeight | scrollFullHeight | stacked
          print: false,
          selectableRows: 'multiple',
          selectableRowsOnClick: false,
          onRowsDelete: (rowsDeleted) => {
            if (props.can_delete) {
              rowsDeleted.data.forEach((row) => {
                const user = props.store_users[props.store_id][row.dataIndex];
                StoreUserModel.delete_store_user(props.store_id, user.id);
              });
            }
          },
          expandableRows: props.can_update,
          customToolbar: () => (
            <>
              {
                props.can_create &&
                <Tooltip title='Create Store User'>
                  <IconButton onClick={() => set_create_mode(true)}>
                    <AddIcon/>
                  </IconButton>
                </Tooltip>
              }
              <Tooltip title='Manage Store Roles'>
                <IconButton onClick={() => set_roles_mode(true)}>
                  <FontAwesomeIcon icon={faUserCog} />
                </IconButton>
              </Tooltip>
            </>
          ),
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            return (
              <TableRow style={{backgroundColor: '#eee'}}>
                <TableCell colSpan={colSpan}>
                  <Grid container style={{justifyContent: 'center'}}>
                    <Grid container item sm={12} md={6}>
                      <div className='header'>
                        <h2>Edit Store User</h2>
                      </div>
                      <StoreUserForm store_id={props.store_id}
                                     store_user={props.store_users[props.store_id][rowMeta.dataIndex]}/>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            );
          },
        }}
      />
    </>
  );
};

StoreUsers.propTypes = {
  store_id: PropTypes.string.isRequired,
  can_create: PropTypes.bool.isRequired,
  can_update: PropTypes.bool.isRequired,
  can_delete: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  store_users: {
    ...state.store_users
  },
  store_user_roles: {
    ...state.store_user_roles
  },
});

export default connect(mapStateToProps)(StoreUsers);
