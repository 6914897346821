import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import update from 'immutability-helper';
import {Grid, Button, List, ListItem, Typography} from '@material-ui/core';
import {StoreSurveysModel} from "../../models/store_surveys";
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import SurveyQuestion from './store_survey_question_form';
import {FittedSurveyModel} from '../../models/fitted_surveys';
import LoadingButton from "../../components/loading_button/loading_button";
import AddIcon from '@material-ui/icons/Add';
import { withSnackbar } from 'notistack';
import Model from "../../models/base";

const StoreSurveyForm = (props) => {

  const [survey_data, set_survey_data] = useState(null);
  const [store_survey_data, set_store_survey_data] = useState(null);
  const [fitted_survey_data, set_fitted_survey_data] = useState(null);

  const survey_id = props.match.params.survey_id;

  useEffect(() => {
    // Get Store surveys/questions
    if(props.store_id && survey_id) {
      if(!props.store_surveys[props.store_id] || !props.store_surveys[props.store_id][survey_id])
        return StoreSurveysModel.get_store_surveys(props.store_id);
      else if(!store_survey_data)
        set_store_survey_data({...(props.store_surveys[props.store_id][survey_id])});

      if(!props.store_surveys[props.store_id][survey_id].questions)
        return StoreSurveysModel.get_store_survey_questions(props.store_id, survey_id);
      else if(store_survey_data && !store_survey_data.questions)
        set_store_survey_data({
          ...store_survey_data,
          questions: [...(props.store_surveys[props.store_id][survey_id].questions)]
        });
    }
  }, [store_survey_data, props.store_id, survey_id, props.store_surveys, props.store_surveys[props.store_id]]);

  useEffect(() => {
    // Get Fitted surveys/questions
    if(props.store_surveys[props.store_id]) {
      const survey_category = props.store_surveys[props.store_id][survey_id].category;

      if(!props.fitted_surveys[survey_category])
        return FittedSurveyModel.get_fitted_survey_by_category_id(survey_category);
      else if(!fitted_survey_data)
        set_fitted_survey_data({...(props.fitted_surveys[survey_category])});
      else if(!fitted_survey_data.questions && props.fitted_surveys[survey_category].questions)
        set_fitted_survey_data({...(props.fitted_surveys[survey_category])});
    }
  }, [fitted_survey_data, props.store_id, props.fitted_surveys]);



  useEffect(() => {
    if(!survey_data && store_survey_data && store_survey_data.questions &&
      fitted_survey_data && fitted_survey_data.questions)
    {
      const question_orders = store_survey_data.question_orders || {};

      const questions = Model.sort_by([
        ...(store_survey_data.questions),
        ...(fitted_survey_data.questions.map(q => ({...q, fitted: true})))
      ].map(q => ({
        ...q,
        // Updated the question orders based on store's preference
        order: (question_orders[q.id] !== undefined ? question_orders[q.id] : q.order)
      })), 'order');

      set_survey_data({ ...store_survey_data, questions });
    }
  }, [survey_data, store_survey_data, fitted_survey_data]);

  // const handleFormChange = useCallback((data) => {
  //   set_survey_data({
  //     ...survey_data,
  //     ...data
  //   });
  // }, [survey_data, set_survey_data]);

  // const handle_question_change = useCallback((question) => {
  //   let questions = {};
  //   survey_data.questions.forEach((q)=> questions[q.id] = {...q});
  //   questions[question.id] = {...question};
  //   questions = Object.values(questions).sort((a, b) => (a.sort > b.sort ? 1 : (a.sort < b.sort ? -1 : 0)));
  //   set_survey_data({...survey_data, questions });
  // }, [survey_data, set_survey_data]);

  const handle_question_change = useCallback((question, option) => {
    // Convert the question array to question map
    let questions = {};
    survey_data.questions.forEach((q)=> questions[q.id] = {...q});

    questions[question.id] = {
      ...question,
    };

    if(option) {
      let is_new_option = true;
      for(let i = 0; i < questions[question.id].options.length; i++) {
        if(questions[question.id].options[i].id === option.id) {
          questions[question.id].options[i] = {...option};
          is_new_option = false;
          break;
        }
      }
      if(is_new_option)
        questions[question.id].options.push({...option});
    }

    questions = Object.values(questions).sort((a, b) => (a.sort > b.sort ? 1 : (a.sort < b.sort ? -1 : 0)));
    set_survey_data({...survey_data, questions });
  }, [survey_data, set_survey_data]);

  const moveQuestion = useCallback((dragIndex, hoverIndex) => {
    const dragCard = survey_data.questions[dragIndex];
    const questions = update(survey_data.questions, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    });
    questions.forEach((o, i) => questions[i].order = i);
    set_survey_data({
      ...survey_data,
      questions
    });
  }, [survey_data]);

  // const save_survey = useCallback((callback) => {
  //   StoreSurveysModel
  //     .update_store_survey(props.store_id, survey_data.id, {name: survey_data.name})
  //     .then(() => {
  //       callback();
  //     })
  //     .catch((error) => {
  //       set_form_validation(error);
  //       callback();
  //     });
  // }, [survey_data]);

  const save_survey_questions = useCallback((callback) => {
    StoreSurveysModel
      .set_store_survey_questions(props.store_id, survey_data.id, survey_data.questions)
      .then(() => {
        props.enqueueSnackbar('Survey questions are updated.', {variant: 'success'});
        callback();
      })
      .catch((errors) => {
        console.log(errors);
        errors.forEach((errorObject) => {
          Object.values(errorObject).forEach((message) => {
            props.enqueueSnackbar(message, {variant: 'error', preventDuplicate: true});
          });
        });
        callback();
      })
  }, [props.store_id, survey_data]);

  const add_new_question = useCallback(() => {
    set_survey_data({
      ...survey_data,
      questions: [
        ...(survey_data.questions),
        StoreSurveysModel.generate_new_survey_question(survey_data.questions.length)
      ]
    });
  }, [survey_data]);

  const delete_question = useCallback((question) => {
    if(question.id) {
      StoreSurveysModel
        .delete_store_survey_question(props.store_id, survey_data.id, question.id)
        .then(() => {
          set_survey_data({
            ...survey_data,
            questions: survey_data.questions.filter(q => q.id !== question.id)
          });
        });
    }
  }, [survey_data]);

  useEffect(() => {
    console.log('survey_data:', survey_data);
  }, [survey_data]);

  if(!props.survey_id)
    return <p>Unknown survey!</p>;

  if(!survey_data)
    return <b>Loading ...</b>;

  return (
    <Grid container spacing={1} style={{margin: '30px auto 0', width: '100%', maxWidth: 800}}>
      <Grid container item xs={12}>
        <Typography variant="h5" gutterBottom>
          Customize your survey questions
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          You can not change the default questions but you
          have the option to sort them or create your own questions.
        </Typography>
      </Grid>
      <Grid container item xs={12} sm={6} >
        {/*<TextField label="Name"*/}
                   {/*type="text"*/}
                   {/*required*/}
                   {/*margin="dense"*/}
                   {/*size="small"*/}
                   {/*variant="outlined"*/}
                   {/*value={survey_data.name || ''} fullWidth*/}
                   {/*onChange={e => handleFormChange({name: e.target.value})}*/}
                   {/*error={!!form_validation.name}*/}
                   {/*helperText={form_validation.name} />*/}
      </Grid>
      <Grid container item>
        <DndProvider backend={Backend}>
          <List component="nav" style={{width: '100%', padding: '5px 15px',
            border: '2px dashed #eee', 'minHeight': '180px'}}>
            {
              survey_data.questions &&
              survey_data.questions.map((question, i) => (
                <SurveyQuestion key={question.id}
                                index={i}
                                id={question.id}
                                question={question}
                                onMove={moveQuestion}
                                onDelete={() => delete_question(question)}
                                onChange={handle_question_change} />
              ))
            }
            <ListItem style={{padding: '0 0 10px'}}>
              <Button variant='outlined' onClick={add_new_question}>
                <AddIcon /> Add New Question
              </Button>
            </ListItem>
          </List>
        </DndProvider>
      </Grid>
      <Grid container item xs={12}>
        <LoadingButton text='Save'
                       confirm_title='Confirm Action'
                       confirm_message='Are you sure you want to save the changes? This action can not be reversed.'
                       confirm_accept='Yes, Save changes'
                       confirm_reject='No, Cancel'
                       onPress={save_survey_questions}/>
      </Grid>
    </Grid>
  );
};

StoreSurveyForm.defaultProps = {
  onClose: () => {},
};

StoreSurveyForm.propTypes = {
  store_id: PropTypes.string.isRequired,
  survey_id: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

const mapStateToProps = state => ({
  store_surveys: {
    ...state.store_surveys
  },
  fitted_surveys: {
    ...state.fitted_surveys
  }
});

export default withRouter(withSnackbar(connect(mapStateToProps)(StoreSurveyForm)));
