import React, {useEffect, useState, useCallback} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {fade, makeStyles, withStyles} from '@material-ui/core/styles';
import {TreeView, TreeItem} from '@material-ui/lab';
import {Button, SvgIcon, FormControlLabel, Checkbox} from '@material-ui/core';
import {StoreShoesModel} from "../../models/store_shoes";
import { withSnackbar } from 'notistack';


const useStyles = makeStyles({
  root: {
    '-webkit-user-select': 'none',  /* Safari */
    '-moz-user-select': 'none',     /* Firefox */
    '-ms-user-select': 'none',      /* IE10+/Edge */
    'user-select': 'none',          /* Standard */
  },
});

const ShoesSelection = (props) => {

  const classes = useStyles();
  const [brands, set_brands] = useState([]);
  const [shoes, set_shoes] = useState([]);

  const [hidden_brands, set_hidden_brands] = useState({});
  const [hidden_shoes, set_hidden_shoes] = useState({});


  useEffect(() => {
    // After global list of shoes or brands changed update the local list
    // If this component is opened in a store page we need to filter the
    // lists and only shoe the shoes that are not hidden (archived)
    set_brands([...props.brands.items]);
    if(props.store_id)
      set_shoes(props.shoes.items.filter(s=>!s.archived));
    else
      set_shoes([...props.shoes.items]);
  }, [props.brands.items, props.shoes.items]);


  useEffect(() => {
    // If component is used on the store page get and update store's hidden shoes
    if(props.store_id) {
      const current_store_shoes = props.store_shoes[props.store_id];
      if (!current_store_shoes) {
        StoreShoesModel.get_shoes_visibility_by_store_id(props.store_id);
      }
      else {
        set_hidden_brands(current_store_shoes['hidden_brands'] || {});
        set_hidden_shoes(current_store_shoes['hidden_shoes'] || {});
      }
    }
  }, [props.store_id, props.store_shoes]);


  const handleBrandChange = useCallback((e, brand) => {
    // Handle checkbox event
    const hidden = !e.target.checked;
    set_hidden_brands({
      ...hidden_brands, [brand]: hidden
    });

    // Checking or Un checking a brand should do the same to all the child shoes
    const h = {};
    shoes
      .filter(s=>s.brand===brand)
      .forEach(s=>{
        h[s.id] = hidden
      });
    set_hidden_shoes({...hidden_shoes, ...h});
  }, [hidden_brands, hidden_shoes, shoes]);


  // const saveGlobalShoesVisibility = useCallback(() => {
  //   if(!props.store_id)
  //     ShoeModel.update_global_shoes_visibility(hidden_shoes);
  // }, [hidden_shoes]);


  const saveStoreShoesVisibility = useCallback(() => {
    if(props.store_id && props.can_update)
      StoreShoesModel
        .update_shoes_visibility_by_store_id(props.store_id, hidden_brands, hidden_shoes)
        .then(() => {
          props.enqueueSnackbar('Shoes selections are updated.', {variant: 'success'});
        })
        .catch(() => {
          props.enqueueSnackbar('Saving shoes selections was unsuccessful.', {variant: 'error'});
        });
  }, [hidden_brands, hidden_shoes, props.store_id]);


  if(!props.brands.items || !props.shoes.items)
    return <b>Loading ...</b>;


  return (
    <>
      <TreeView
        className={classes.root}
        defaultExpanded={['1']}
        defaultCollapseIcon={<MinusSquare/>}
        defaultExpandIcon={<PlusSquare/>}
      >
        {
          brands.map((brand, i) => {
            const checked = props.store_id ? !hidden_brands[brand] : true;
            return <StyledTreeItem nodeId={brand} label={brand} key={i}
                                   checked={checked}
                                   onChange={(e) => handleBrandChange(e, brand)}>
              {
                shoes
                  .filter(shoe => shoe.brand === brand)
                  .map((shoe, j) => {
                    const checked = props.store_id ? !hidden_shoes[shoe.id] : !shoe.archived;
                    return <StyledTreeItem nodeId={shoe.id} label={shoe.name} key={j}
                                           checked={checked}
                                           onChange={(e) => set_hidden_shoes({
                                             ...hidden_shoes,
                                             [shoe.id]: !e.target.checked
                                           })}/>
                  })
              }
            </StyledTreeItem>
          })
        }
      </TreeView>
      <div style={{textAlign: 'center', padding: '20px 0 10px'}}>
        {
          props.store_id && props.can_update &&
          <Button onClick={saveStoreShoesVisibility} variant="contained" color="primary">
            Save
          </Button>
        }
        {/*{*/}
          {/*!props.store_id && Role.user_has_capability(Role.capabilities.update_all_shoes_selections) &&*/}
          {/*<Button onClick={saveGlobalShoesVisibility} variant="contained" color="primary">*/}
            {/*Save*/}
          {/*</Button>*/}
        {/*}*/}
      </div>
    </>
  );
};

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path
        d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z"/>
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path
        d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z"/>
    </SvgIcon>
  );
}

function TreeItemLabel(props) {
  return <FormControlLabel
    style={{marginLeft:5}}
    control={
      <Checkbox
        checked={props.checked}
        onChange={props.onChange}
        onClick={e => e.stopPropagation()}
        value={true}
        color="primary"
      />
    }
    label={props.label}
    onClick={e => e.stopPropagation()}/>
}

const StyledTreeItem = withStyles(theme => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 12,
    paddingLeft: 12,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
}))(props => {
  return <TreeItem children={props.children}
                   nodeId={props.nodeId}
                   classes={props.classes}
                   label={<TreeItemLabel {...props} />} />
});

ShoesSelection.propTypes = {
  brands: PropTypes.object.isRequired,
  shoes: PropTypes.object.isRequired,
  store_id: PropTypes.string,
  can_update: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  shoes: state.shoes,
  brands: state.brands,
  store_shoes: {
    ...state.store_shoes
  },
  role: state.role
});

export default withSnackbar(connect(mapStateToProps)(ShoesSelection));
