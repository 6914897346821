import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Grid, TextField, Button, FormGroup,FormControl, FormLabel,
  FormControlLabel, Checkbox} from '@material-ui/core';
import {StoreUserModel} from "../../models/store_users";
import {StoreUserRoleModel} from "../../models/store_user_roles";


const StoreUserForm = (props) => {

  const [form_data, set_form_data] = useState(props.store_user ?
    {roles: {}, ...props.store_user} : {roles: {}});
  const [form_validation, set_form_validation] = useState({});

  const [roles, set_roles] = useState(
    !!form_data.stores &&
    !!form_data.stores[props.store_id] &&
    !!form_data.stores[props.store_id].roles ? form_data.stores[props.store_id].roles : {}
  );

  useEffect(() => {
    if (props.store_id && !props.store_user_roles[props.store_id])
      StoreUserRoleModel.get_store_user_roles(props.store_id);
  }, []);

  const handleFormChange = useCallback((data) => {
    set_form_data({
      ...form_data,
      ...data
    });
  }, [form_data, set_form_data]);

  const handleRoleChange = useCallback((role) => {
    const new_roles = {...roles};
    if(new_roles[role.id])
      delete new_roles[role.id];
    else
      new_roles[role.id] = StoreUserRoleModel.get_store_role_reference(props.store_id, role.id);
    set_roles(new_roles);
  }, [form_data, roles]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    set_form_validation({});

    if(props.store_user) {
      StoreUserModel
        .update_store_user(props.store_id, form_data.id, form_data, Object.keys(roles))
        .then(() => {
          props.onClose();
        })
        .catch(error => {
          set_form_validation(error)
        });
    }
    else {
      StoreUserModel
        .create_store_user(props.store_id, form_data, Object.keys(roles))
        .then(() => { props.onClose(); })
        .catch(error => set_form_validation(error));
    }
  }, [props.store_id, form_data, roles]);

  return (
    <form method="post" noValidate onSubmit={handleSubmit} style={{width: '100%'}}>
      <Grid container spacing={1}>
        <Grid container item xs={12} sm={6} >
          <TextField label="First Name" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     value={form_data.first_name || ''} fullWidth
                     onChange={e => handleFormChange({first_name: e.target.value})}
                     error={!!form_validation.first_name}
                     helperText={form_validation.first_name} />
        </Grid>
        <Grid container item xs={12} sm={6} >
          <TextField label="Last Name" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     value={form_data.last_name || ''} fullWidth
                     onChange={e => handleFormChange({last_name: e.target.value})}
                     error={!!form_validation.last_name}
                     helperText={form_validation.last_name} />
        </Grid>
        <Grid container item xs={12} sm={6} >
          <TextField label="Email" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     value={form_data.email || ''} fullWidth
                     onChange={e => handleFormChange({email: e.target.value})}
                     error={!!form_validation.email}
                     helperText={form_validation.email} />
        </Grid>
        <Grid container item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Assign Roles</FormLabel>
            <FormGroup>
              {
                props.store_user_roles && props.store_user_roles[props.store_id] &&
                props.store_user_roles[props.store_id]
                  .sort((a, b)=>(a.label > b.label ? 1 : (a.label < b.label ? -1 : 0)))
                  .map((role, i) => (
                    <FormControlLabel label={role.label} key={i} control={
                      <Checkbox checked={!!roles[role.id]} value={role.id} color="primary"
                                onChange={() => handleRoleChange(role)} />
                    } />
                  )
                )
              }
            </FormGroup>
            {/*<FormHelperText>Be careful</FormHelperText>*/}
          </FormControl>
        </Grid>
        <Grid container item xs={12} >
          <Button color="primary" size='small' margin="normal"
                  type='submit' variant='contained' style={{margin: '10px 10px 30px 0'}}>
            {props.store_user ? 'Update' : 'Create'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

StoreUserForm.defaultProps = {
  onClose: () => {},
};

StoreUserForm.propTypes = {
  store_id: PropTypes.string.isRequired,
  store_user: PropTypes.object,
  onClose: PropTypes.func,
};

const mapStateToProps = state => ({
  store_user_roles: {
    ...state.store_user_roles
  },
});

export default connect(mapStateToProps)(StoreUserForm);
