import firebase from "firebase";
import Model from "./base";
import {ReduxStore} from "./redux";
import {baseActionTypes} from "./base";


const initialState = { };

export const storeShoesReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_STORE_SHOES_SELECTIONS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};


export const actionTypes = {
  GET_STORE_SHOES_SELECTIONS: 'GET_STORE_SHOES_SELECTIONS',
};


export class StoreShoesModel extends Model {

  static async get_store_visible_shoes(store_id) {

    const shoes = await firebase
      .firestore()
      .collection('shoes')
      .get();

    const hidden_brands = await firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('shoes')
      .doc('hidden_brands')
      .get();

    const hidden_shoes = await firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('shoes')
      .doc('hidden_shoes')
      .get();

    const store_shoes = [];
    const hidden_brands_data = hidden_brands && hidden_brands.data() && hidden_brands.data();
    const hidden_shoes_data = hidden_shoes && hidden_shoes.data() && hidden_shoes.data();
    shoes.docs.forEach((doc) => {
      if(hidden_brands_data && hidden_brands_data[doc.data().brand])
        return;
      if(hidden_shoes_data && hidden_shoes_data[doc.id])
        return;

      store_shoes.push({
        id: doc.id,
        ...doc.data()
      });
    });

    return Model.sort_by(Model.sort_by(store_shoes, 'name'), 'brand');
  }

  static get_shoes_visibility_by_store_id(store_id) {
    firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('shoes')
      .onSnapshot((documentSnapshots) => {
        const result = {};
        documentSnapshots.docs.forEach((doc) => {
          result[doc.id] = doc.data();
        });
        ReduxStore.dispatch({
          type: actionTypes.GET_STORE_SHOES_SELECTIONS,
          payload: {[store_id]: result}
        });
      })
  }

  static update_shoes_visibility_by_store_id(store_id, hidden_brands, hidden_shoes) {
    const saved_brands = firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('shoes')
      .doc('hidden_brands')
      .set(hidden_brands)
      .catch(error => {
        ReduxStore.dispatch({
          type: baseActionTypes.FIRE_STORE_ERROR,
          error
        });
      });

    const saved_shoes = firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('shoes')
      .doc('hidden_shoes')
      .set(hidden_shoes)
      .catch(error => {
        ReduxStore.dispatch({
          type: baseActionTypes.FIRE_STORE_ERROR,
          error
        });
      });

    return Promise.all([saved_brands, saved_shoes]);
  }

}
