import dev from './development';
import prod from './production';

let config = {};

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  config = dev;
}
else {
  config = prod;
}

export default config;
