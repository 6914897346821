import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Grid, TextField, Button, Chip} from '@material-ui/core';
import {StoreModel} from '../../models/store';
import {Autocomplete} from '@material-ui/lab';
import { withSnackbar } from 'notistack';


const StoreProfileForm = (props) => {

  const [form_data, set_form_data] = useState(props.store ? {...props.store} : {});

  const [form_validation, set_form_validation] = useState({});

  const handleFormChange = useCallback((data) => {
    set_form_data({
      ...form_data,
      ...data
    });
  }, [form_data, set_form_data]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    set_form_validation({});

    if(props.store) {
      StoreModel
        .update_store_by_id(form_data.id, form_data)
        .then(() => {
          props.closeForm();
          props.enqueueSnackbar('Store profile is updated.', {variant: 'success'});
        })
        .catch(error => {
          set_form_validation(error);
          props.enqueueSnackbar('Updating store profile was unsuccessful.', {variant: 'error'});
        });
    }
    else {
      StoreModel
        .create_store(form_data)
        .then(() => {
          props.closeForm();
          props.enqueueSnackbar('Store profile is created.', {variant: 'success'});
        })
        .catch(error => {
          set_form_validation(error);
          props.enqueueSnackbar('Creating store profile was unsuccessful.', {variant: 'error'});
        });
    }

  }, [form_data]);

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid container item xs={12} md={10} lg={8}>
        <form method="post" noValidate onSubmit={handleSubmit} style={{width: '100%'}}>

          <div className='header'>
            <h2>
              {props.store ? 'Edit Store' : 'Create Store'}
              <Button color="secondary" size='small' margin="normal" onClick={props.closeForm}
                      variant='contained' style={{margin: '-4px 0px 0px 20px'}}>
                Back
              </Button>
            </h2>
          </div>

          <Grid container spacing={1}>
            <Grid container item xs={12} sm={6} md={5} lg={4} >
              <TextField label="Name" type="text"
                         required
                         margin="dense"
                         size="small"
                         variant="outlined"
                         value={form_data.name || ''} fullWidth
                         onChange={e => handleFormChange({name: e.target.value})}
                         error={!!form_validation.name}
                         helperText={form_validation.name} />
            </Grid>
            <Grid container item xs={12} sm={6} md={5} lg={4} >
              <TextField label="Email" type="text"
                         required
                         margin="dense"
                         size="small"
                         variant="outlined"
                         value={form_data.email || ''} fullWidth
                         onChange={e => handleFormChange({email: e.target.value})}
                         error={!!form_validation.email}
                         helperText={form_validation.email} />
            </Grid>
            <Grid container item xs={12} sm={6} md={5} lg={4} >
              <TextField label="Street Address" type="text"
                         required
                         margin="dense"
                         size="small"
                         variant="outlined"
                         value={form_data.street_address || ''} fullWidth
                         onChange={e => handleFormChange({street_address: e.target.value})}
                         error={!!form_validation.street_address}
                         helperText={form_validation.street_address} />
            </Grid>
            <Grid container item xs={12} sm={6} md={5} lg={4} >
              <TextField label="City" type="text"
                         required
                         margin="dense"
                         size="small"
                         variant="outlined"
                         value={form_data.city || ''} fullWidth
                         onChange={e => handleFormChange({city: e.target.value})}
                         error={!!form_validation.city}
                         helperText={form_validation.city} />
            </Grid>
            <Grid container item xs={12} sm={6} md={5} lg={4} >
              <TextField label="State" type="text"
                         required
                         margin="dense"
                         size="small"
                         variant="outlined"
                         value={form_data.state || ''} fullWidth
                         onChange={e => handleFormChange({state: e.target.value})}
                         error={!!form_validation.state}
                         helperText={form_validation.state} />
            </Grid>
            <Grid container item xs={12} sm={6} md={5} lg={4} >
              <TextField label="Country" type="text"
                         required
                         margin="dense"
                         size="small"
                         variant="outlined"
                         value={form_data.country || ''} fullWidth
                         onChange={e => handleFormChange({country: e.target.value})}
                         error={!!form_validation.country}
                         helperText={form_validation.country} />
            </Grid>
            <Grid container item xs={12} sm={6} md={5} lg={4} >
              <TextField label="Zip Code" type="text"
                         required
                         margin="dense"
                         size="small"
                         variant="outlined"
                         value={form_data.zipcode || ''} fullWidth
                         onChange={e => handleFormChange({zipcode: e.target.value})}
                         error={!!form_validation.zipcode}
                         helperText={form_validation.zipcode} />
            </Grid>
            <Grid container item xs={12} sm={6} md={5} lg={4} >
              <TextField label="Website"  type="text"
                         required
                         margin="dense"
                         size="small"
                         variant="outlined"
                         value={form_data.website || ''} fullWidth
                         onChange={e => handleFormChange({website: e.target.value})}
                         error={!!form_validation.website}
                         helperText={form_validation.website} />
            </Grid>
            <Grid container item xs={12} sm={6} md={5} lg={4} >
              <TextField label="Fitted Store Url"  type="text"
                         required
                         margin="dense"
                         size="small"
                         variant="outlined"
                         value={form_data.fitted_store_url || ''} fullWidth
                         onChange={e => handleFormChange({fitted_store_url: e.target.value})}
                         error={!!form_validation.fitted_store_url}
                         helperText={form_validation.fitted_store_url} />
            </Grid>
            <Grid container item xs={12} sm={6} md={5} lg={4} >
              <TextField label="Sq ft" type="text"
                         required
                         margin="dense"
                         size="small"
                         variant="outlined"
                         value={form_data.sq_ft || ''} fullWidth
                         onChange={e => handleFormChange({sq_ft: e.target.value})}
                         error={!!form_validation.sq_ft}
                         helperText={form_validation.sq_ft} />
            </Grid>
            <Grid container item xs={12} sm={6} md={5} lg={4} >
              <TextField label="Net Sales" type="text"
                         required
                         margin="dense"
                         size="small"
                         variant="outlined"
                         value={form_data.net_sales || ''} fullWidth
                         onChange={e => handleFormChange({net_sales: e.target.value})}
                         error={!!form_validation.net_sales}
                         helperText={form_validation.net_sales} />
            </Grid>
            <Grid container item xs={12} sm={6} md={5} lg={4} >
              <TextField label="Median Income" type="text"
                         required
                         margin="dense"
                         size="small"
                         variant="outlined"
                         value={form_data.median_income || ''} fullWidth
                         onChange={e => handleFormChange({median_income: e.target.value})}
                         error={!!form_validation.median_income}
                         helperText={form_validation.median_income} />
            </Grid>
            {/*<Grid container item xs={12} sm={6} md={5} lg={4} >*/}
              {/*<Autocomplete*/}
                {/*multiple margin="dense" size="small"*/}
                {/*id="store-tags" options={[]}*/}
                {/*getOptionLabel={option => option.title}*/}
                {/*defaultValue={[]}*/}
                {/*renderTags={(value, getTagProps) =>*/}
                  {/*value.map((option, index) => (*/}
                    {/*<Chip disabled={index === 0} label={option.title} {...getTagProps({ index })} />*/}
                  {/*))*/}
                {/*}*/}
                {/*style={{ width: 500 }}*/}
                {/*renderInput={params => (*/}
                  {/*<TextField*/}
                    {/*{...params}*/}
                    {/*label="Tags"*/}
                    {/*variant="outlined"*/}
                    {/*placeholder="Store tags"*/}
                    {/*fullWidth*/}
                  {/*/>*/}
                {/*)}*/}
              {/*/>*/}
            {/*</Grid>*/}
            <Grid container item xs={12} >
              <Button color="primary" size='small' margin="normal"
                      type='submit' variant='contained' style={{margin: '10px 10px 30px 0'}}>
                {props.store ? 'Update' : 'Create'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

StoreProfileForm.propTypes = {
  store: PropTypes.object,
  closeForm: PropTypes.func.isRequired,
};

export default withSnackbar(StoreProfileForm);
