import React, { useRef, useState, useCallback } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { List, ListItem, ListItemText, Button, Collapse, Grid, TextField, Typography,
  InputLabel, MenuItem, FormControl, Select} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import HeightIcon from '@material-ui/icons/Height';
import SurveyQuestionOption from './store_survey_question_option_form';
import update from 'immutability-helper'
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import {StoreSurveysModel} from "../../models/store_surveys";
import LoadingButton from "../../components/loading_button/loading_button";

const ItemTypes = {
  QUESTION: 'question',
};

const style = {
  margin: '10px 0',
  cursor: 'ns-resize',
};

const SurveyQuestion = ({ id, question, index, onChange, onMove, onDelete }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.QUESTION,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      onMove(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  });
  const [expanded, set_expanded] = useState(false);

  const [{isDragging}, drag] = useDrag({
    item: {type: ItemTypes.QUESTION, id, index},
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const borderColor = question.fitted ? '#607D8B' : (question.enabled ? '#00a307' : '#f00');

  return (
    <div ref={ref} style={{...style, opacity}}>
      <ListItem style={{
        minHeight: '50px', border: '1px solid #ddd', borderLeft: ('4px solid ' + borderColor),
        padding: 0, backgroundColor: '#f3f3f3',
      }}>
        <HeightIcon style={{margin: '0 5px 0 10px'}}/>
        <div onClick={()=>set_expanded(!expanded)}
                style={{flex: 1, textAlign: 'left', backgroundColor: 'transparent',
                  display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
          {expanded ? <ExpandLess /> : <ExpandMore />}
          <ListItemText primary={`${index + 1}. ${question.label}`} style={{padding: '0 10px'}}/>
        </div>
        {
          !question.fitted &&
          <LoadingButton icon={<DeleteIcon style={{ color: '#ff0000' }} />} variant="text"
                         confirm_title='Confirm Action'
                         confirm_message='Are you sure you want to delete this question? This action can not be reversed.'
                         confirm_accept='Yes, Delete This Question'
                         confirm_reject='No, Cancel'
                         onPress={onDelete} />
        }
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit
                style={{border: '2px dashed #eee', padding: '25px', borderTop: 0}} >
        {
          question.fitted ?
            <Typography variant="body1" gutterBottom>
              This is a default question managed by Fitted Inc.
            </Typography>  :
            <SurveyQuestionForm question={question} onChange={onChange} />
        }
      </Collapse>
    </div>
  )
};

const SurveyQuestionForm = ({question, onChange}) => {

  const handle_form_change = useCallback((data) => {
    onChange({
      ...question,
      ...data
    });
  }, [question, onChange]);

  const moveQuestionOption = useCallback((dragIndex, hoverIndex) => {
    const dragCard = question.options[dragIndex];
    const options = update(question.options, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    });
    options.forEach((o, i) => options[i].order = i);
    onChange({
      ...question,
      options
    });
  }, [question, onChange]);

  const add_new_option = useCallback(() => {
    onChange(question, StoreSurveysModel.generate_new_survey_question_option(question.options.length));
  }, [question, onChange]);

  const delete_option = useCallback((option) => {
    if(option.id) {
      onChange(question, {
        ...option,
        deleted: true
      });
    }
  }, [question, onChange]);

  return (
    <Grid container spacing={1}>
      <Grid container item xs={12} >
        <TextField label="Label"
                   draggable
                   onDragStart={e => {
                     e.preventDefault();
                     e.stopPropagation();
                   }}
                   id="outlined-basic"
                   type="text"
                   required
                   margin="dense"
                   size="small"
                   variant="outlined"
                   value={question.label || ''} fullWidth
                   onChange={e => handle_form_change({label: e.target.value})} />
      </Grid>
      <Grid container item xs={12} sm={6} md={4} >
        <FormControl fullWidth variant="outlined" margin="dense" size="small">
          <InputLabel>Type</InputLabel>
          <Select value={question.type || 'textbox'} labelWidth={40}
                  onChange={(e) => handle_form_change({type: e.target.value})} >
            <MenuItem value='checkbox'>Multiple Choice</MenuItem>
            <MenuItem value='radiobox'>Single Choice</MenuItem>
            <MenuItem value='textbox'>Text</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid container item xs={12} sm={6} md={4} >
        <FormControl fullWidth variant="outlined" margin="dense" size="small">
          <InputLabel>Status</InputLabel>
          <Select value={question.enabled} labelWidth={50}
                  onChange={(e) => handle_form_change({enabled: e.target.value})} >
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid container item xs={12}>
      {
        ['radiobox', 'checkbox'].indexOf(question.type) >= 0 &&
        <List component="nav" style={{width: '100%'}}>
          {
            question && question.options &&
            question.options
              .filter((option) => (!option.deleted))
              .map((option, i) => {
                return (
                  <SurveyQuestionOption key={option.id}
                                        index={i}
                                        id={option.id}
                                        option={option}
                                        onMove={moveQuestionOption}
                                        onDelete={() => delete_option(option)}
                                        onChange={(option) => onChange(question, option)}
                  />
                )
            })
          }
          <Button variant='outlined' onClick={add_new_option}>
            <AddIcon /> Add New Option
          </Button>
        </List>
      }
      </Grid>
    </Grid>
  );
};

export default SurveyQuestion;
