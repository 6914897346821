import React from 'react';
import MasterLayout from "../layouts/master";


const NoPage = () => {
  return (
    <MasterLayout>
      <h3>Page Not Found</h3>
      <p>Sorry, the page you are looking for does not exist!</p>
    </MasterLayout>
  );
};

export default NoPage;
