import Model from "./base";
import Joi from '@hapi/joi';
import firebase from "firebase";
import {ReduxStore} from './redux';


const initialState = {
  items: [],
  loading: false,
  error: null
};

export const brandReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_SHOE_BRANDS_BEGIN:
      return {
        ...state,
        items: [],
        loading: true,
        error: null
      };

    case actionTypes.GET_SHOE_BRANDS_SUCCESS:
      return {
        ...state,
        items: action.payload.items.sort(),
        loading: false
      };

    case actionTypes.GET_SHOE_BRANDS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    default:
      return state;
  }
};


export const actionTypes = {
  GET_SHOE_BRANDS_BEGIN: 'GET_SHOE_BRANDS_BEGIN',
  GET_SHOE_BRANDS_SUCCESS: 'GET_SHOE_BRANDS_SUCCESS',
  GET_SHOE_BRANDS_ERROR: 'GET_SHOE_BRANDS_ERROR',
};


export class BrandModel extends Model {

  name = 'Brand';
  collection_path = '/brands';

  columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      }
    }
  ];

  static create_validation = Joi.object({
    name: Joi.string().min(3).max(50).required(),
  }).options({stripUnknown: true, abortEarly: false});

  static edit_validation = Joi.object({
    name: Joi.string().min(3).max(50).required(),
  }).options({stripUnknown: true, abortEarly: false});

  static get_all_items() {
    ReduxStore.dispatch({type: actionTypes.GET_SHOE_BRANDS_BEGIN});

    firebase
      .firestore()
      .collection('shoes')
      .get()
      .then((shoeDocs) => {
        const brands_dict = {};
        shoeDocs.forEach((shoeDoc) => {
          brands_dict[shoeDoc.data().brand] = true;
        });

        const items = Object.keys(brands_dict);
        console.log("Brands Fetched", items);
        ReduxStore.dispatch({
          type: actionTypes.GET_SHOE_BRANDS_SUCCESS,
          payload: {items}
        });
      })
      .catch((error) => {
        console.log("Brands Failed", error);
        ReduxStore.dispatch({
          type: actionTypes.GET_SHOE_BRANDS_ERROR,
          payload: {error}
        });
      });
  }
}
