import React, {useState, useEffect, useCallback} from "react"
import connect from "react-redux/es/connect/connect";
import { Grid, FormControl, TextField, Button, withStyles, FormHelperText } from "@material-ui/core"
import {Link, withRouter} from "react-router-dom";
import { blue } from '@material-ui/core/colors';
import Auth from "../../models/auth";
import config from "../../config";

import "./login.scss";

const ColoredTextField = withStyles({
  root: {
    border: '1px solid #0392ff',
    borderRadius: '3px 3px 0 0',
    // borderBottom: 0,

    '& :before, & :hover:before': {
      borderBottomColor: blue[500]//'#0392ff'
    },
    '& :after': {
      borderBottomColor: '#927cee'
    },

    '& .MuiFilledInput-root': {
      backgroundColor: '#fff'
    },
    '& .MuiInputLabel-filled': {
      color: blue[500] //'#0392ff'
    }
  },
})(TextField);

const ColoredButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    width: '100px',
    '&:hover': {
      backgroundColor: blue[700],
    },
    '& .MuiButton-label': {
      textTransform: 'none',
      fontWeight: 600,
    }
  }
}))(Button);

const Login = (props) => {

  const [email, set_email] = useState('');
  const [password, set_password] = useState('');
  const [error, set_error] = useState();

  useEffect(() => {
    // If user is logged in redirect to the home page
    if(props.auth && props.auth.uid) {
      props.history.push(config.routes.base);
    }
  }, [props.auth]);

  const login = useCallback((e) => {
    // Login form was submitted
    e.preventDefault();
    set_error('');
    // Trying to authenticate the user. If user login is successful
    // props.auth will be updated which results in a redirect to the home page
    Auth
      .login(email, password)
      .catch((error) => {
        set_error(error.message);
      })
  }, [email, password, set_error]);

  return (
    <Grid container className="login">
      <Grid container item xs={true} sm={6} md={7} lg={8}>
        <div className="image">
        </div>
      </Grid>
      <Grid container item xs={12} sm={6} md={5} lg={4}>
        <form method="post" noValidate onSubmit={login}>

          <div className='logo'>
            <img src='/images/logo.svg' />
          </div>

          <div className='header'>
            <h2>Join Our Community</h2>
            <p>More than 10 Million users connected with happy face.</p>
          </div>

          <ColoredTextField id="email" label="Email" variant="filled" type="email"
                     size="small" required margin="dense"
                     value={email} onChange={e => {set_email(e.target.value)}}
                     // error helperText="Incorrect login info."
          />

          <ColoredTextField id="password" label="Password" variant="filled" type="password"
                     size="small" required margin="dense"
                     value={password} onChange={e => {set_password(e.target.value)}}
                     // error={!!error} helperText={error}
          />
          {
            !!error && <FormHelperText error={true}>{error}</FormHelperText>
          }

          <FormControl>
            <p className="terms">
              By clicking below, I agree to the
              <Link to="/terms-conditions"> Terms and Conditions</Link>
            </p>
          </FormControl>

          <FormControl>
            {/*<Link to="/password/reset" style={{color: "#505050"}}>Forgot Your Password?</Link>*/}
            <ColoredButton color="primary" size='small' margin="normal" type='submit'>
              Login
            </ColoredButton>
          </FormControl>
        </form>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps)(Login));
