import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import MUIDataTable from "mui-datatables";
import {TableRow, TableCell, Dialog, DialogTitle,DialogContent,Grid, Tooltip, IconButton} from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import StoreLocationForm from './store_location_form';
import {StoreLocationsModel} from "../../models/store_locations";
import PropTypes from 'prop-types';


const StoreLocations = (props) => {

  const [create_mode, set_create_mode] = useState(false);

  useEffect(() => {
    if(props.store_id && !props.store_locations[props.store_id])
      StoreLocationsModel.get_store_locations_by_store_id(props.store_id);
  }, [props.store_id, props.store_locations]);

  if(!props.store_id || !props.store_locations[props.store_id])
    return <b>Loading ...</b>;

  return (
    <div>
      <Dialog
        scroll='body'
        fullWidth
        maxWidth='sm'
        open={create_mode}
        onClose={()=>set_create_mode(false)}
      >
        <DialogTitle>Create New Location</DialogTitle>
        <DialogContent>
          <StoreLocationForm store_id={props.store_id}
                             closeForm={() => set_create_mode(false)} />
        </DialogContent>
      </Dialog>

      <MUIDataTable
        title="Store Locations"
        data={props.store_locations[props.store_id]}
        columns={StoreLocationsModel.columns}
        options={{
          filter: false,
          responsive: "scrollFullHeight",  // scrollMaxHeight | scrollFullHeight | stacked
          print: false,
          selectableRows: (props.can_delete ? 'multiple' : 'none'),
          selectableRowsOnClick: false,
          onRowsDelete: (rowsDeleted) => {
            if(props.can_delete) {
              rowsDeleted.data.forEach((row) => {
                const location = props.store_locations[props.store_id][row.dataIndex];
                StoreLocationsModel.delete_store_location_by_location_id(props.store_id, location.id);
              });
            }
          },
          expandableRows: props.can_update,
          customToolbar: () => (
            props.can_create &&
            <Tooltip title='Create location'>
              <IconButton onClick={() => set_create_mode(true)}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          ),
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            return (
              <TableRow style={{backgroundColor: '#eee'}}>
                <TableCell colSpan={colSpan}>
                  <Grid container style={{justifyContent: 'center'}}>
                    <Grid container item sm={12} md={6} >
                      <div className='header'>
                        <h2>Edit Shoes</h2>
                      </div>
                      <StoreLocationForm store_id={props.store_id}
                        store_location={props.store_locations[props.store_id][rowMeta.dataIndex]} />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            );
          },
        }}
      />
    </div>
  );
};

StoreLocations.propTypes = {
  store_id: PropTypes.string.isRequired,
  can_create: PropTypes.bool.isRequired,
  can_update: PropTypes.bool.isRequired,
  can_delete: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  store_locations: {
    ...state.store_locations
  },
});

export default connect(mapStateToProps)(StoreLocations);
