import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {ShoeModel} from '../../models/shoe';
import {
  Grid, TextField, Button, FormControl, InputLabel,
  Select, MenuItem, FormControlLabel, Checkbox, FormHelperText
} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {BrandModel} from "../../models/brand";


const ShoeForm = (props) => {

  const [form_data, set_form_data] = useState(props.shoe ? {...props.shoe} : {});

  const [form_validation, set_form_validation] = useState({});

  useEffect(()=> {
    if(!props.brands.items)
      BrandModel.get_all_items();
  }, []);

  const handleFormChange = useCallback((data) => {
    set_form_data({
      ...form_data,
      ...data
    });
  }, [form_data, set_form_data]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    set_form_validation({});

    if(props.shoe) {
      ShoeModel
        .update_shoe_by_id(form_data.id, form_data)
        .then(() => { props.onClose(); })
        .catch(error => set_form_validation(error));
    }
    else {
      ShoeModel
        .create_shoe(form_data)
        .then(() => { props.onClose(); })
        .catch(error => set_form_validation(error));
    }

  }, [form_data]);

  return (
    <form method="post" noValidate onSubmit={handleSubmit} style={{width: '100%'}}>
      <Grid container spacing={1}>
        <Grid container item xs={12} sm={6} >
          <TextField label="Name" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     value={form_data.name || ''} fullWidth
                     onChange={e => handleFormChange({name: e.target.value})}
                     error={!!form_validation.name}
                     helperText={form_validation.name} />
        </Grid>
        <Grid container item xs={12} sm={6} >
          <Autocomplete
            margin="dense"
            size="small"
            variant="outlined"
            style={{width: '100%', margin: '8px 0 4px'}}
            options={props.brands.items}
            getOptionLabel={option => option}
            // defaultValue={form_data.brand}
            value={form_data.brand}
            onChange={(e, v)=>handleFormChange({brand: v})}
            renderInput={params => (
              <TextField {...params} label="Brand" variant="outlined" fullWidth />
            )}
          />
          {
            form_validation.brand &&
            <FormHelperText error={true}>{form_validation.brand}</FormHelperText>
          }
        </Grid>
        <Grid container item xs={12} sm={6} >
          <FormControl fullWidth
                       margin="dense"
                       size="small"
                       variant="outlined">
            <InputLabel id="stability-select-label">Group</InputLabel>
            <Select
              labelId="stability-select-label"
              id="stability-select"
              labelWidth={50}
              value={form_data.stability || ''} fullWidth
              onChange={e => handleFormChange({stability: e.target.value})} >
              <MenuItem value='trail'>Trail</MenuItem>
              <MenuItem value='stability'>Stability</MenuItem>
              <MenuItem value='neutral'>Neutral</MenuItem>
            </Select>
            {
              form_validation.stability &&
              <FormHelperText error={true}>{form_validation.stability}</FormHelperText>
            }
          </FormControl>
        </Grid>
        <Grid container item xs={12} sm={6} >
          <FormControlLabel control={
            <Checkbox checked={!!form_data.archived}
                      onChange={e => handleFormChange({archived: !!!form_data.archived})}
                      value={!!form_data.archived} />
          }
                            label="Archived" />
        </Grid>
        <Grid container item xs={12} >
          <Button color="primary" size='small' margin="normal"
                  type='submit' variant='contained' style={{margin: '10px 10px 30px 0'}}>
            {props.shoe ? 'Update' : 'Create'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

ShoeForm.defaultProps = {
  onClose: () => {},
  shoe: null
};

ShoeForm.propTypes = {
  shoe: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  brands: state.brands,
});

export default connect(mapStateToProps)(ShoeForm);
