import React from 'react';
import {connect} from "react-redux";
import MasterLayout from "../layouts/master";
import StoreReport from "../common/report/main";


const HomePage = (props) => {
  return (
    <MasterLayout title='Dashboard'>
      {
        props.stores.current_store_id &&
          <StoreReport store_id={props.stores.current_store_id} />
      }
    </MasterLayout>
  );
};

const mapStateToProps = state => ({
  stores: state.stores
});

export default connect(mapStateToProps)(HomePage);
