import React from 'react';
import {connect} from "react-redux";
import MasterLayout from "../layouts/master";
import Role from "../../models/role";
import StoreSurveys from "../common/store_surveys";


const StoreSurveysPage = (props) => {
  return (
    <MasterLayout title='Store Surveys'>
      {
        props.stores.current_store_id &&
        <StoreSurveys store_id={props.stores.current_store_id}
                      can_create={Role.user_has_capability(Role.capabilities.create_own_surveys)}
                      can_update={Role.user_has_capability(Role.capabilities.update_own_surveys)}
                      can_delete={Role.user_has_capability(Role.capabilities.delete_own_surveys)} />
      }
    </MasterLayout>
  );
};

StoreSurveysPage.propTypes = {};

const mapStateToProps = state => ({
  stores: state.stores
});

export default connect(mapStateToProps)(StoreSurveysPage);
