import React from 'react';
import {connect} from "react-redux";
import MasterLayout from "../layouts/master";


const SurveysPage = () => {
  return (
    <MasterLayout title='Surveys'>
      Ability to manage the default survey for all stores coming soon!

    </MasterLayout>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(SurveysPage);
