import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import MasterLayout from "../layouts/master";
import {Autocomplete} from "@material-ui/lab";
import {TextField, Typography} from "@material-ui/core";
import StoreReport from "../common/report/main";


const HomePage = (props) => {

  const [selected_store, set_selected_store] = useState(null);
  const [store_options, set_store_options] = useState([]);

  useEffect(() => {
    if (props.stores.all_stores && props.stores.all_stores.length) {
      set_store_options(props.stores.all_stores.map((store) => ({
        id: store.id,
        name: store.name,
      })));
    }
  }, [props.stores.all_stores]);


  return (
    <MasterLayout
      app_bar={
        <div style={{
          flex: 1,
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center'
        }}>
          <Typography variant="h6" noWrap style={{marginLeft: 20}}>
            Dashboard
          </Typography>
          <Autocomplete
            size="small"
            margin="dense"
            options={store_options}
            value={selected_store}
            getOptionLabel={store => store.name}
            style={{ width: 300 }}
            onChange={(e, store) => { console.log(store); set_selected_store(store ? store : {}) }}
            renderInput={params => (
              <TextField {...params} label="Select Store" variant="outlined" fullWidth />
            )}
          />
        </div>
      }
    >
      {
        selected_store && selected_store.id ?
        <StoreReport store_id={selected_store.id} user={props.user}/>
          :
        <h3>Select a store to view the report</h3>
      }
    </MasterLayout>
  );
};

HomePage.propTypes = {
  // dispatch: PropTypes.func.isRequired,
  // path: PropTypes.string.isRequired,
  // component: PropTypes.oneOfType([
  //   PropTypes.object.isRequired,
  //   PropTypes.func.isRequired,
  // ]),
  // capability: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth,
  stores: state.stores
});

export default connect(mapStateToProps)(HomePage);
