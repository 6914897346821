import React from 'react';
import {Redirect} from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import config from "./../config";
import {RoleTypes} from "../models/role";
import MasterLayout from "../containers/layouts/master";


const BaseRoute = (props) => {

  if(!props.auth || !props.auth.uid)
    return <Redirect to={config.routes.login} />;

  if(!props.role.current_role_type)
    return <b>Loading ...</b>;

  if(props.role.current_role_type === RoleTypes.fitted)
    return <Redirect to={config.routes.fitted.base} />;

  else if(props.role.current_role_type === RoleTypes.stores)
    return <Redirect to={config.routes.store.base} />;

  return (
    <MasterLayout>
      <h3>Sorry, your account does not have an appropriate role to use the platform.</h3>
    </MasterLayout>
  );

};

BaseRoute.propTypes = { };

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.role,
});

export default connect(mapStateToProps)(BaseRoute);
