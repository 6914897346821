import React from "react";
import clsx from "clsx";
import connect from "react-redux/es/connect/connect";
import {NavLink, withRouter} from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PeopleIcon from '@material-ui/icons/People';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoePrints, faTags, faCommentAlt, faStoreAlt, faSignOutAlt, faTachometerAlt
} from '@fortawesome/free-solid-svg-icons';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import Auth from "../../models/auth";
import config from "../../config";
import Role, {RoleTypes} from "../../models/role";
import {ReduxStore} from "../../models/redux";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: '60px',
    [theme.breakpoints.up("sm")]: {
      width: '60px'
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  MuiListItemIcon: {
    fontSize: '25px'
  }
}));


const StyledListItem = withStyles({
  root: {
    transition: 'none',
    "&.active": {
      backgroundColor: "#4154B3"
    },
    "&:hover": {
      backgroundColor: "#4154B3"
    },
    "&.active, &:hover, &.active path, &:hover path": {
      fill: '#fff',
      color: '#fff'
    },
  },
  selected: {}
})(ListItem);


const Sidebar = (props) => {
  const classes = useStyles();

  const {role} = ReduxStore.getState();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: props.open,
        [classes.drawerClose]: !props.open
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open
        })
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={props.onClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List style={{padding: 0}}>
        {
          Role.user_has_capability(Role.capabilities.read_all_reports) &&
          <StyledListItem button exact component={NavLink} to={config.routes.fitted.home} activeClassName={'active'}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faTachometerAlt} size="lg" />
            </ListItemIcon>
            <ListItemText primary={'Dashboard'} />
          </StyledListItem>
        }
        {
          Role.user_has_capability(Role.capabilities.read_own_reports) &&
          <StyledListItem button exact component={NavLink} to={config.routes.store.home} activeClassName={'active'}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faTachometerAlt} size="lg" />
            </ListItemIcon>
            <ListItemText primary={'Dashboard'} />
          </StyledListItem>
        }
        {
          Role.user_has_capability(Role.capabilities.read_all_stores) &&
          <StyledListItem button exact={false} component={NavLink} to={config.routes.fitted.stores} activeClassName={'active'}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faStoreAlt} size="lg" />
            </ListItemIcon>
            <ListItemText primary={'Stores'} />
          </StyledListItem>
        }
        {
          Role.user_has_capability(Role.capabilities.read_own_stores) &&
          <StyledListItem button exact={false} component={NavLink} to={config.routes.store.profile} activeClassName={'active'}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faStoreAlt} size="lg" />
            </ListItemIcon>
            <ListItemText primary={'Stores'} />
          </StyledListItem>
        }
        {
          Role.user_has_capability(Role.capabilities.read_own_locations) &&
          <StyledListItem button exact={false} component={NavLink} to={config.routes.store.locations} activeClassName={'active'}>
            <ListItemIcon>
              <LocationOnIcon />
            </ListItemIcon>
            <ListItemText primary={'Locations'} />
          </StyledListItem>
        }
        {
          Role.user_has_capability(Role.capabilities.read_common_shoes) &&
          <StyledListItem button component={NavLink} to={config.routes.fitted.shoes} activeClassName={'active'}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faShoePrints} size="lg"/>
            </ListItemIcon>
            <ListItemText primary={'Shoes'}/>
          </StyledListItem>
        }
        {
          Role.user_has_capability(Role.capabilities.read_own_shoes_selections) &&
          <StyledListItem button component={NavLink} to={config.routes.store.shoes} activeClassName={'active'}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faShoePrints} size="lg" />
            </ListItemIcon>
            <ListItemText primary={'Shoes'}/>
          </StyledListItem>
        }
        {
          Role.user_has_capability(Role.capabilities.read_fitted_users) &&
          <StyledListItem button component={NavLink} to={config.routes.fitted.users} activeClassName={'active'}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary={'Users'}/>
          </StyledListItem>
        }
        {
          role.current_role_type === RoleTypes.stores &&
          Role.user_has_capability(Role.capabilities.read_store_users) &&
          <StyledListItem button component={NavLink} to={config.routes.store.users} activeClassName={'active'}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary={'Users'}/>
          </StyledListItem>
        }
        {
          Role.user_has_capability(Role.capabilities.read_own_employees) &&
          <StyledListItem button component={NavLink} to={config.routes.store.employees} activeClassName={'active'}>
            <ListItemIcon>
              <TabletMacIcon />
            </ListItemIcon>
            <ListItemText primary={'Fitters'}/>
          </StyledListItem>
        }
        {/*{*/}
          {/*Role.user_has_capability(Role.capabilities.read_all_surveys) &&*/}
          {/*<StyledListItem button component={NavLink} to={config.routes.fitted.surveys} activeClassName={'active'}>*/}
            {/*<ListItemIcon>*/}
              {/*<FontAwesomeIcon icon={faCommentAlt} size="lg"/>*/}
            {/*</ListItemIcon>*/}
            {/*<ListItemText primary={'Surveys'}/>*/}
          {/*</StyledListItem>*/}
        {/*}*/}
        {
          Role.user_has_capability(Role.capabilities.read_own_surveys) &&
          <StyledListItem button component={NavLink} to={config.routes.store.surveys} activeClassName={'active'}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faCommentAlt} size="lg"/>
            </ListItemIcon>
            <ListItemText primary={'Surveys'}/>
          </StyledListItem>
        }
        {
          Role.user_has_capability(Role.capabilities.read_all_tags) &&
          <StyledListItem button component={NavLink} to={config.routes.fitted.tags} activeClassName={'active'}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faTags} size="lg"/>
            </ListItemIcon>
            <ListItemText primary={'Tags'}/>
          </StyledListItem>
        }
      </List>
      <List style={{padding: 0}}>
        <StyledListItem button onClick={Auth.logout}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faSignOutAlt} size="lg"/>
          </ListItemIcon>
          <ListItemText primary={'Log Out'}/>
        </StyledListItem>
      </List>
    </Drawer>
  );
};

Sidebar.defaultProps = {
  open: true,
  onClose: () => {}
};

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.role,
});

export default withRouter(connect(mapStateToProps)(Sidebar));
