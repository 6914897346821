import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import MUIDataTable from "mui-datatables";
import {
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Tooltip,
  IconButton
} from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import StoreUserRoleForm from './store_user_role_form';
import PropTypes from 'prop-types';
import {StoreUserRoleModel} from "../../models/store_user_roles";


const StoreUserRoles = (props) => {

  const [create_mode, set_create_mode] = useState(false);

  useEffect(() => {
    if (props.store_id && !props.store_user_roles[props.store_id])
      StoreUserRoleModel.get_store_user_roles(props.store_id);
  }, []);

  if (!props.store_id || !props.store_user_roles[props.store_id])
    return <b>Loading ...</b>;

  return (
    <>
      <Dialog scroll='body' maxWidth='sm' fullWidth open={create_mode}
              onClose={() => set_create_mode(false)}>
        <DialogTitle>Create User Role</DialogTitle>
        <DialogContent>
          <StoreUserRoleForm store_id={props.store_id}
                             onClose={() => set_create_mode(false)}/>
        </DialogContent>
      </Dialog>

      <MUIDataTable
        title="User Roles"
        data={props.store_user_roles[props.store_id]}
        columns={StoreUserRoleModel.columns}
        options={{
          filterType: "dropdown",
          responsive: "scrollFullHeight",  // scrollMaxHeight | scrollFullHeight | stacked
          print: false,
          selectableRows: 'multiple',
          selectableRowsOnClick: false,
          onRowsDelete: (rowsDeleted) => {
            if (props.can_delete) {
              rowsDeleted.data.forEach((row) => {
                const role = props.store_user_roles[props.store_id][row.dataIndex];
                StoreUserRoleModel.delete_store_user_role(props.store_id, role.id);
              });
            }
          },
          expandableRows: props.can_update,
          customToolbar: () => (
            props.can_create &&
            <Tooltip title='Create User Role'>
              <IconButton onClick={() => set_create_mode(true)}>
                <AddIcon/>
              </IconButton>
            </Tooltip>
          ),
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            return (
              <TableRow style={{backgroundColor: '#eee'}}>
                <TableCell colSpan={colSpan}>
                  <Grid container style={{justifyContent: 'center'}}>
                    <Grid container item sm={12} md={6}>
                      <div className='header'>
                        <h2>Edit User Role</h2>
                      </div>
                      <StoreUserRoleForm store_id={props.store_id}
                                         store_user_role={props.store_user_roles[props.store_id][rowMeta.dataIndex]}/>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            );
          },
        }}
      />
    </>
  );
};

StoreUserRoles.propTypes = {
  store_id: PropTypes.string.isRequired,
  can_create: PropTypes.bool.isRequired,
  can_update: PropTypes.bool.isRequired,
  can_delete: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  store_user_roles: {
    ...state.store_user_roles
  },
});

export default connect(mapStateToProps)(StoreUserRoles);
