import React, {useEffect, useState, useCallback} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {fade, makeStyles, withStyles} from '@material-ui/core/styles';
import {TreeView, TreeItem} from '@material-ui/lab';
import {SvgIcon, FormControlLabel, Checkbox} from '@material-ui/core';


const useStyles = makeStyles({
  root: {
    flex: 1,
    '-webkit-user-select': 'none',  /* Safari */
    '-moz-user-select': 'none',     /* Firefox */
    '-ms-user-select': 'none',      /* IE10+/Edge */
    'user-select': 'none',          /* Standard */
  },
});

const TreeViewSelections = (props) => {

  const classes = useStyles();

  const on_selection_change = useCallback((items) => {
    const selections = {...props.selections};

    items
      .forEach((item) => {
        selections[item.id] = item.value;
      });

    Object
      .keys(selections)
      .forEach((id) => {
        if(selections[id] !== true)
          delete selections[id];
      });

    props.onChange(selections);
  }, [props.selections]);

  // const [brands, set_brands] = useState([]);
  // const [shoes, set_shoes] = useState([]);
  //
  // const [hidden_brands, set_hidden_brands] = useState({});
  // const [hidden_shoes, set_hidden_shoes] = useState({});
  //
  // useEffect(() => {
  //   // After global list of shoes or brands changed update the local list
  //   // If this component is opened in a store page we need to filter the
  //   // lists and only shoe the shoes that are not hidden (archived)
  //   set_brands([...props.brands.items]);
  //   if(props.store_id)
  //     set_shoes(props.shoes.items.filter(s=>!s.archived));
  //   else
  //     set_shoes([...props.shoes.items]);
  // }, [props.brands.items, props.shoes.items]);
  //
  //
  // useEffect(() => {
  //   // If component is used on the store page get and update store's hidden shoes
  //   if(props.store_id) {
  //     const current_store_shoes = props.store_shoes[props.store_id];
  //     if (!current_store_shoes) {
  //       StoreShoesModel.get_shoes_visibility_by_store_id(props.store_id);
  //     }
  //     else {
  //       set_hidden_brands(current_store_shoes['hidden_brands'] || {});
  //       set_hidden_shoes(current_store_shoes['hidden_shoes'] || {});
  //     }
  //   }
  // }, [props.store_id, props.store_shoes]);
  //
  //
  // const handleBrandChange = useCallback((e, brand) => {
  //   // Handle checkbox event
  //   const hidden = !e.target.checked;
  //   set_hidden_brands({
  //     ...hidden_brands, [brand]: hidden
  //   });
  //
  //   // Checking or Un checking a brand should do the same to all the child shoes
  //   const h = {};
  //   shoes
  //     .filter(s=>s.brand===brand)
  //     .forEach(s=>{
  //       h[s.id] = hidden
  //     });
  //   set_hidden_shoes({...hidden_shoes, ...h});
  // }, [hidden_brands, hidden_shoes, shoes]);
  //
  //
  // const saveGlobalShoesVisibility = useCallback(() => {
  //   if(!props.store_id)
  //     ShoeModel.update_global_shoes_visibility(hidden_shoes);
  // }, [hidden_shoes]);
  //
  //
  // const saveStoreShoesVisibility = useCallback(() => {
  //   if(props.store_id)
  //     StoreShoesModel
  //       .update_shoes_visibility_by_store_id(props.store_id, hidden_brands, hidden_shoes)
  // }, [hidden_brands, hidden_shoes, props.store_id]);

  if(!props.data)
    return <b>Loading ...</b>;

  return (
    <>
      <TreeView
        className={classes.root}
        defaultExpanded={['1']}
        defaultCollapseIcon={<MinusSquare/>}
        defaultExpandIcon={<PlusSquare/>}
        defaultEndIcon={<CloseSquare/>}
      >
        <StyledTreeItem nodeId='1' label='Capabilities' selectable={false} onChange={(e) => {}}>
          <TreeLevelItems items={props.data}
                          selections={props.selections}
                          onChange={on_selection_change} />
        </StyledTreeItem>
      </TreeView>
    </>
  );
};

function TreeLevelItems(props) {

  const update_children = (item, changed_records, value) => {
    if(item && item.children) {
      item.children.forEach(child => {
        changed_records.push({id: child.id, value});
        update_children(child, changed_records, value);
      });
    }
  };

  const onChange = (item) => {
    const value = !props.selections[item.id];
    const changed_records = [{id: item.id, value}];

    // Auto select correlated items
    if(item.requirements && value)
      item.requirements.forEach((id) => {
        if(props.selections[id] !== value)
          changed_records.push({id, value});
      });

    // Auto select child items when a parent is selected
    update_children(item, changed_records, value);
    props.onChange(changed_records);
  };

  return (
    props.items.map((item, i) => (
      <StyledTreeItem nodeId={item.id} label={item.label} key={i}
                      checked={!!props.selections[item.id]}
                      selectable
                      onChange={() => onChange(item)}>
        {
          item.children &&
          <TreeLevelItems items={item.children}
                          selections={props.selections}
                          onChange={props.onChange}/>
        }
      </StyledTreeItem>
    ))
  );
}

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path
        d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z"/>
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path
        d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z"/>
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon className="close" fontSize="inherit" {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TreeItemLabel(props) {
  return <FormControlLabel
    style={{marginLeft:5}}
    control={
      props.selectable ?
      <Checkbox
        checked={props.checked}
        onChange={props.onChange}
        // indeterminate
        onClick={e => e.stopPropagation()}
        value={true}
        color="primary"
      /> : <></>
    }
    label={props.label}
    onClick={e => e.stopPropagation()}/>
}

const StyledTreeItem = withStyles(theme => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 12,
    paddingLeft: 12,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
}))(props => {
  return <TreeItem children={props.children}
                   nodeId={props.nodeId}
                   classes={props.classes}
                   label={<TreeItemLabel {...props} />} />
});

TreeViewSelections.propTypes = {
  data: PropTypes.array.isRequired,
  selections: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ });

export default connect(mapStateToProps)(TreeViewSelections);
