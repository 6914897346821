import moment from 'moment';
import firebase from 'firebase';


const initialState = {
  error: null,
};

export const baseReducer = (state = initialState, action) => {
  switch (action.type) {

    case baseActionTypes.FIRE_STORE_ERROR:
      console.error(action.error);
      return {
        ...state,
        error: action.error
      };

    default:
      return state;
  }
};


export const baseActionTypes = {
  FIRE_STORE_ERROR: 'FIRE_STORE_ERROR',
  API_ERROR: 'API_ERROR'
};


export default class Model {

  static formatError(error) {
    const result = {};
    if (error && Array.isArray(error.details)) {
      error.details.forEach(err => {
        result[err.context.key] = err.message;
      });
    }
    return result;
  }

  static format_firestore_timestamp(timestamp) {
    if(timestamp && timestamp.seconds) {
      const date = moment(timestamp.seconds * 1000);
      const format = date.format('MMM DD YYYY HH:mm:ss');
      return format;
    }
    return null;
  }

  static generate_new_id() {
    return firebase.firestore().collection('fake_collection').doc().id;
  }

  static get_current_timestamp() {
    return firebase.firestore.Timestamp.now()
  }

  static sort_by(array = [], key = 'order') {
    return array.sort((a, b) => (a[key] > b[key] ? 1 : (a[key] < b[key] ? -1 : 0)));
  }

  static map_to_array(map = {}, sort_by = 'order') {
    const array = Object
      .keys(map)
      .map(id => ({ id, ...(map[id]) }));

    if(sort_by)
      return Model.sort_by(array);

    return array;
  }

  static array_to_map(array = [], key = 'id', remove_key = true) {
    const map = {};
    array.forEach((item) => {
      const temp = {...item};
      if(remove_key)
        delete temp[key];
      map[item[key]] = temp;
    });
    return map;
  }
}
