import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Grid, TextField, Button, FormControlLabel, Checkbox} from '@material-ui/core';
import {StoreEmployeesModel} from "../../models/store_employee";
import { withSnackbar } from 'notistack';


const StoreEmployeeForm = (props) => {

  const [form_data, set_form_data] = useState(props.store_employee ? {...props.store_employee} : {});

  const [form_validation, set_form_validation] = useState({});

  const handleFormChange = useCallback((data) => {
    set_form_data({
      ...form_data,
      ...data
    });
  }, [form_data, set_form_data]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    set_form_validation({});

    if(props.store_employee) {
      StoreEmployeesModel
        .update_store_employee_by_employee_id(props.store_id, form_data.id, form_data)
        .then(() => {
          props.enqueueSnackbar('Employee record is updated.', {variant: 'success'});
          props.onClose();
        })
        .catch(error => {
          if(error.duplicate_pin)
            props.enqueueSnackbar(error.duplicate_pin, {variant: 'error'});
          else
            set_form_validation(error);
        });
    }
    else {
      StoreEmployeesModel
        .create_store_employee_by_store_id(props.store_id, form_data)
        .then(() => {
          props.enqueueSnackbar('Employee record is updated.', {variant: 'success'});
          props.onClose();
        })
        .catch(error => {
          if(error.duplicate_pin)
            props.enqueueSnackbar(error.duplicate_pin, {variant: 'error'});
          else
            set_form_validation(error);
        });
    }
  }, [form_data]);

  return (
    <form method="post" noValidate onSubmit={handleSubmit} style={{width: '100%'}}>
      <Grid container spacing={1}>
        <Grid container item xs={12} sm={6} >
          <TextField label="First Name" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     value={form_data.first_name || ''} fullWidth
                     onChange={e => handleFormChange({first_name: e.target.value})}
                     error={!!form_validation.first_name}
                     helperText={form_validation.first_name} />
        </Grid>
        <Grid container item xs={12} sm={6} >
          <TextField label="Last Name" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     value={form_data.last_name || ''} fullWidth
                     onChange={e => handleFormChange({last_name: e.target.value})}
                     error={!!form_validation.last_name}
                     helperText={form_validation.last_name} />
        </Grid>
        <Grid container item xs={12} sm={6} >
          <TextField label="Email" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     value={form_data.email || ''} fullWidth
                     onChange={e => handleFormChange({email: e.target.value})}
                     error={!!form_validation.email}
                     helperText={form_validation.email} />
        </Grid>
        <Grid container item xs={12} sm={6} >
          <TextField label="Pin Code" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     value={form_data.pin || ''} fullWidth
                     onChange={e => handleFormChange({pin: e.target.value})}
                     error={!!form_validation.pin}
                     helperText={form_validation.pin} />
        </Grid>
        <Grid container item xs={12} sm={6} >
          <FormControlLabel label="Archived" control={
            <Checkbox checked={!!form_data.archived}
                      onChange={() => handleFormChange({archived: !!!form_data.archived})}
                      value={!!form_data.archived} />
          } />
        </Grid>
        <Grid container item xs={12} >
          <Button color="primary" size='small' margin="normal"
                  type='submit' variant='contained' style={{margin: '10px 10px 30px 0'}}>
            {props.store_employee ? 'Update' : 'Create'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

StoreEmployeeForm.defaultProps = {
  onClose: () => {},
};

StoreEmployeeForm.propTypes = {
  store_id: PropTypes.string.isRequired,
  store_employee: PropTypes.object,
  onClose: PropTypes.func,
};

export default withSnackbar(StoreEmployeeForm);
