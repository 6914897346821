import React, {useEffect} from 'react';
import {Switch, Redirect} from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import config from "./../config";
import PrivateRoute from "./private";
import Role from "../models/role";
import {ShoeModel} from "../models/shoe";
import {BrandModel} from "../models/brand";
import {StoreModel} from "../models/store";
import HomePage from "../containers/fitted/home";
import StoresPage from "../containers/fitted/stores";
import StorePage from "../containers/fitted/store";
import ShoesPage from "../containers/fitted/shoes";
import UsersPage from "../containers/fitted/fitted_users_page";
import SurveysPage from "../containers/fitted/surveys";
import TagsPage from "../containers/fitted/tags";


const FittedAdminRoutes = (props) => {

  useEffect(() => {
    // Initially load all the data
    if(!props.shoes.items.length)
      ShoeModel.get_all_shoes();
    if(!props.brands.items.length)
      BrandModel.get_all_items();
    if(!props.stores.all_stores.length)
      StoreModel.get_all_stores();
  }, []);

  return (
    <Switch>

      <PrivateRoute exact path={config.routes.fitted.home} component={HomePage}
                    capability={Role.capabilities.read_all_reports} />

      <PrivateRoute path={config.routes.fitted.store} component={StorePage}
                    capability={''} />

      <PrivateRoute exact path={config.routes.fitted.stores} component={StoresPage}
                    capability={Role.capabilities.read_all_stores} />

      <PrivateRoute exact path={config.routes.fitted.shoes} component={ShoesPage}
                    capability={Role.capabilities.read_common_shoes} />

      <PrivateRoute exact path={config.routes.fitted.users} component={UsersPage}
                    capability={Role.capabilities.read_fitted_users} />

      <PrivateRoute exact path={config.routes.fitted.surveys} component={SurveysPage}
                    capability={Role.capabilities.read_all_surveys} />

      <PrivateRoute exact path={config.routes.fitted.tags} component={TagsPage}
                    capability={Role.capabilities.read_all_tags} />

      <Redirect to={config.routes.fitted.home} />

    </Switch>
  );
};

FittedAdminRoutes.propTypes = {};

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.role,
  shoes: state.shoes,
  brands: state.brands,
  stores: state.stores,
});

export default connect(mapStateToProps)(FittedAdminRoutes);
