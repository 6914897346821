import React from 'react';
import MasterLayout from "../layouts/master";
import connect from "react-redux/es/connect/connect";
import PropTypes from 'prop-types';


const NoAccessPage = (props) => {
  return (
    <MasterLayout>
      <h3>Access denied</h3>
      <p>You do not have permission to visit this page!</p>

      <p>
        <label>
          Your Current Role: <strong style={{textTransform: 'capitalize'}}>
            {formatRoleKey(props.role.current_role)}
          </strong>
        </label>
      </p>
      <p>
        <label>
          Capability Needed: <strong style={{textTransform: 'capitalize'}}>
            {formatRoleKey(props.capability)}
          </strong>
        </label>
      </p>
      <p>
        <label>Current page url: <strong>
            {window.location.href}
          </strong>
        </label>
      </p>
    </MasterLayout>
  );
};

const formatRoleKey = (key) => {
  return key ? key.replace(/_/g, ' ') : '';
};

NoAccessPage.propTypes = {
  path: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.role,
});

export default connect(mapStateToProps)(NoAccessPage);
