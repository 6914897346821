import React, {useCallback, useState} from 'react';
import {connect} from "react-redux";
import MasterLayout from "../layouts/master";
import MUIDataTable from "mui-datatables";
import {withRouter} from "react-router-dom";
import config from '../../config';
import Role from "../../models/role";
import StoreProfileForm from "../common/store_profile_form";
import {StoreModel} from "../../models/store";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';


const getMuiTheme = () => createMuiTheme({
  overrides: {
    MuiTableRow: {
      root: {
        cursor: 'pointer'
      }
    },
  }
});

const StoresPage = (props) => {

  const [create_mode, set_create_mode] = useState(false);

  const onRowClick = useCallback((rowData, rowMeta) => {
    if (props.stores.all_stores && props.stores.all_stores.length > rowMeta.dataIndex) {
      const store = props.stores.all_stores[rowMeta.dataIndex];
      props.history.push(config.routes.fitted.store_profile.replace(':store_id', store.id));
    }
  }, [props.stores.all_stores, props.history]);

  return (
    <MasterLayout title='Stores'>
      <MuiThemeProvider theme={getMuiTheme()}>
        {
          create_mode &&
          <div style={{
            position: 'absolute', top: 0, bottom: 0, left: 0,
            right: 0, backgroundColor: '#fff', zIndex: 999, padding: '68px 14px 10px'
          }}>
            <StoreProfileForm closeForm={() => {
              StoreModel.get_all_stores();
              set_create_mode(false)
            }}/>
          </div>
        }
        <MUIDataTable
          title="Stores"
          data={props.stores.all_stores}
          columns={StoreModel.columns}
          classes={{}}
          options={{
            print: false,
            filterType: "dropdown",
            responsive: "scrollFullHeight",  // scrollMaxHeight | scrollFullHeight | stacked
            selectableRows: 'none',
            onRowClick,
            customToolbar: () => (
              Role.user_has_capability(Role.capabilities.create_all_store_profiles) &&
              <Tooltip title='Create Store'>
                <IconButton onClick={() => set_create_mode(true)}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            ),
          }}
        />
      </MuiThemeProvider>
    </MasterLayout>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.role,
  stores: state.stores
});

export default withRouter(connect(mapStateToProps)(StoresPage));
