import React from 'react';
import {connect} from "react-redux";
import ShoesSelection from "../common/shoes_selection";
import Role from "../../models/role";


const ShoeSelectionPage = (props) => {
  return (
    <>
      <h2>Shoes Selection</h2>
      <p>
        Here you can choose what brands or shoes should be visible on the app.
      </p>
      <p>
        If you uncheck a brand, all the shoes from that brand will be hidden, even if you check some of the shoes.
      </p>
      <p>
        When new shoes are added, they will be visible on the app unless you hide the brand.
      </p>
      {
        props.store_id &&
        <ShoesSelection store_id={props.store_id}
                        can_update={Role.user_has_capability(Role.capabilities.update_all_shoes_selections)} />
      }
    </>
  );
};

ShoeSelectionPage.propTypes = {};

const mapStateToProps = state => ({
  auth: state.auth,
  stores: state.stores
});

export default connect(mapStateToProps)(ShoeSelectionPage);
