import React, {useCallback, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    minWidth: 300,
    maxWidth: 500,
  },
  divider: {
    margin: '5px 0 0'
  },
  custom_autocomplete: {
    marginTop: 20
  },
  layout_picker: {
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 300,

    '& li': {
      listStyle: 'none',
      backgroundColor: '#eee',
      width: '31.3%',
      height: 30,
      margin: '1%',
      cursor: 'pointer',

      '&.selected': {
        backgroundColor: '#03a9f48c',
      }
    }
  }
}));


const LayoutPopover = ({container_width, container_height, on_layout_change}) => {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [label, set_label] = React.useState();
  const [new_layout, set_new_layout] = React.useState({});

  const open = Boolean(anchorEl);

  useEffect(() => {
    const {rows = 2, cols = 2} = new_layout;
    const obj = {
      rows, cols,
      cell_width: (container_width ? (1 / rows * 100) : 100) + '%',
      cell_height: container_height ? container_height / cols : '100%',
    };
    set_new_layout(obj);
    on_layout_change(obj);
    set_label(`${obj.rows} x ${obj.cols}`);
  }, [container_width, container_height]);

  const handle_select = useCallback((rows, cols) => {
    const obj = { rows, cols };
    set_new_layout(obj);
    return obj;
  }, []);

  const handle_change = useCallback((new_layout) => {
    if(new_layout) {
      new_layout.cell_width = (container_width ? (1 / new_layout.rows * 100) : 100) + '%';
      new_layout.cell_height = container_height ? (container_height / new_layout.cols) : '100%';
      on_layout_change(new_layout);
      set_label(`${new_layout.rows} x ${new_layout.cols}`);
    }
    handle_close();
  }, [container_width, container_height]);

  const handle_close = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <FormControl margin='dense'>
        <InputLabel shrink>Layout</InputLabel>
        <Button variant="outlined" color="primary" size="small"
                onClick={e => setAnchorEl(e.currentTarget)}
                style={{margin: '16px 12px 0 0', minWidth: 90, justifyContent: 'space-between'}}>
          {label} <ArrowDropDownIcon />
        </Button>
      </FormControl>
      <Popover open={open} anchorEl={anchorEl} onClose={handle_close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.container}>
          <Typography variant='h6'>Layout</Typography>
          <ClickAwayListener onClickAway={handle_close}>
            <ul className={classes.layout_picker}>
              {
                [
                  [1, 1], [1, 2], [1, 3],
                  [2, 1], [2, 2], [2, 3],
                  [3, 1], [3, 2], [3, 3],
                ].map(([cols, rows], i) => (
                  <li key={i} onClick={() => { handle_change(handle_select(rows, cols)) }}
                      className={new_layout.cols >= cols && new_layout.rows >= rows ? 'selected' : null}
                      // onMouseOver={() => handle_select(rows, cols)}
                  >
                  </li>
                ))
              }
            </ul>
          </ClickAwayListener>
        </div>
      </Popover>
    </>
  );
};

LayoutPopover.propTypes = {
  container_width: PropTypes.number.isRequired,
  container_height: PropTypes.number.isRequired,
  on_layout_change: PropTypes.func.isRequired,
};

export default LayoutPopover;
