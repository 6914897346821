import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
import Provider from "react-redux/es/components/Provider";
import {ReduxStore} from './models/redux';
import * as serviceWorker from './serviceWorker';
import App from './App';

import './index.css';


const routing = (
  <Provider store={ReduxStore}>
    <Router>
      <App />
    </Router>
  </Provider>
);

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
