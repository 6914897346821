import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Grid, TextField, Button} from '@material-ui/core';
import TreeViewSelections from "../../components/tree_selector/tree_selector";
import Role from "../../models/role";
import {StoreUserRoleModel} from "../../models/store_user_roles";


const StoreUserForm = (props) => {

  const [form_data, set_form_data] = useState(props.store_user_role ? {...props.store_user_role} : {});
  const [selected_capabilities, set_selected_capabilities] = useState({});
  const [form_validation, set_form_validation] = useState({});

  useEffect(() => {
    set_selected_capabilities({
      ...selected_capabilities,
      ...(props.store_user_role ? props.store_user_role.capabilities : {})
    });
  }, [props.store_user_role]);

  const handleFormChange = useCallback((data) => {
    set_form_data({
      ...form_data,
      ...data
    });
  }, [form_data, set_form_data]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    set_form_validation({});

    if(props.store_user_role) {
      StoreUserRoleModel
        .update_store_user_role(props.store_id, form_data.id, {
          ...form_data,
          capabilities: selected_capabilities
        })
        .then(props.onClose)
        .catch(error => set_form_validation(error));
    }
    else {
      StoreUserRoleModel
        .create_store_user_role(props.store_id, {
          ...form_data,
          capabilities: selected_capabilities
        })
        .then(props.onClose)
        .catch(error => set_form_validation(error));
    }
  }, [form_data, selected_capabilities]);

  return (
    <form method="post" noValidate onSubmit={handleSubmit} style={{width: '100%'}}>
      <Grid container spacing={1}>
        <Grid container item xs={12} sm={6}>
          <TextField label="Name" type="text"
                     required
                     margin="dense"
                     size="small"
                     variant="outlined"
                     value={form_data.label || ''} fullWidth
                     onChange={e => handleFormChange({label: e.target.value})}
                     error={!!form_validation.label}
                     helperText={form_validation.label} />
        </Grid>
        <Grid container item xs={12} >
          <TreeViewSelections data={Role.store_user_capabilities}
                              selections={selected_capabilities}
                              onChange={set_selected_capabilities} />
        </Grid>
        <Grid container item xs={12} >
          <Button color="primary" size='small' margin="normal"
                  type='submit' variant='contained' style={{margin: '10px 10px 30px 0'}}>
            {props.store_user_role ? 'Update' : 'Create'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

StoreUserForm.defaultProps = {
  onClose: () => {},
};

StoreUserForm.propTypes = {
  store_id: PropTypes.string.isRequired,
  store_user_role: PropTypes.object,
  onClose: PropTypes.func,
};

export default StoreUserForm;
