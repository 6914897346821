import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import {StoreReportModel} from "../../../models/store_report";
import { makeStyles } from '@material-ui/core/styles';
import StaticChart from './static_chart';
import FusionCharts from 'fusioncharts';
import {IconButton} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';


const useStyles = makeStyles(() => ({
  grid_list: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    height: 'calc(100% - 38px)',
    textAlign: 'center',
    alignItems: 'center',
  },
  grid_cell: {
    height: '100% !important',
    '& > div': {
      height: '100%',
      '& > div': {
        height: '100%'
      }
    }
  },
  chart_header: {
    width: '100%',
    margin: '18px 0 0',
    textAlign: 'center',
    fontSize: 18,
    fontFamily: "Source Sans Pro, Arial",
    fontWeight: 'bold',
    color: '#5b5b5b',
    minHeight: 20,
  },
  download_button: {
    top: 8,
    right: 8,
    color: '#303030',
    position: 'absolute',
    zIndex: 10,
    padding: 6,
  },
  toggle_button: {
    top: '0px',
    color: '#303030',
    left: '8px',
    padding: '6px',
    zIndex: '10',
    position: 'absolute'
  },
  empty_chart: {
    position: 'absolute',
    left: 10,
    right: 10,
    top: 10,
    bottom: 10,
    verticalAlign: 'middle',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  empty_chart_message: {
    width: '100% !important',
    height: 'auto !important',
    padding: 2,
    fontFamily: 'Verdana, sans',
    fontSize: 10,
    textAnchor: 'middle',
  }
}));


const StoreDynamicChart = ({id, store_data, config}) => {

  const classes = useStyles();

  const [data, set_data] = useState();
  const [chart_ids, set_chart_ids] = useState([]);
  const [toggle_dimension, set_toggle_dimension] = useState(false);

  const is_multi_chart = config.type === 'pie2d' && config.dimension1 && config.dimension2;

  useEffect(() => {
    const new_config = {...config};

    let data;
    if(is_multi_chart)
      data = StoreReportModel.calculate_multi_one_dimensional_chart_data(store_data, new_config, toggle_dimension);
    else if(config.type === 'stackedcolumn2d')
      data = StoreReportModel.calculate_two_dimensional_chart_data(store_data, new_config, toggle_dimension);
    else
      data = StoreReportModel.calculate_one_dimensional_chart_data(store_data, new_config);
    set_data(data);
  }, [store_data, config, toggle_dimension]);

  useEffect(() => {
    if(data && ((data.data && data.data.length) || (data.dataset && data.dataset.length))) {
      if (is_multi_chart) {
        if(data.data)
          set_chart_ids(data.data.map((data, i) => `${id}_${i}`));
      }
      else
        set_chart_ids([`${id}_0`]);
    }
  }, [data, is_multi_chart]);

  const export_charts = useCallback(() => {
    const charts = chart_ids.map(id => ({id}));
    FusionCharts.batchExport({
      charts,
      exportFileName: "Export",
      exportFormat: "pdf",
      exportAtClientSide: "1"
    });
  }, [chart_ids]);

  if(!data)
    return null;

  return (
    <>
      <IconButton aria-label="Download" className={classes.download_button}
                  onClick={export_charts}>
        <CloudDownloadIcon/>
      </IconButton>

      {
        config.showToggleButton &&
        <div className={classes.toggle_button}>
          <Tooltip title="Toggle dimension">
            <Switch checked={config.toggle_dimension} value={config.toggle_dimension}
                    onChange={() => set_toggle_dimension(!toggle_dimension)}/>
          </Tooltip>
        </div>
      }

      {
        (data.data && data.data.length) || (data.dataset && data.dataset.length) ?
          <>
          {
            is_multi_chart ?
              <>
                <h4 className={classes.chart_header}>{config.caption}</h4>
                <GridList cols={1} className={classes.grid_list}>
                  {
                    data.data &&
                    data.data.map((data, i) => {
                      return (
                        <GridListTile key={i} className={classes.grid_cell}>
                          <StaticChart data={data} config={{...config, caption: data.title}}
                                       id={`${id}_${i}`} />
                        </GridListTile>
                      );
                    })
                  }
                </GridList>
              </>
              :
              <StaticChart data={data} config={config} id={`${id}_0`} />
          }
          </>
          :
          <div className={classes.empty_chart}>
            <p className={classes.empty_chart_message}>No data to display.</p>
          </div>
      }
    </>
  );
};

StoreDynamicChart.propTypes = {
  store_data: PropTypes.object.isRequired,
  config: PropTypes.shape({
    type: PropTypes.oneOf(['column2d', 'pie2d', 'stackedcolumn2d']),
    caption: PropTypes.string.isRequired,
    subCaption: PropTypes.string,
    xAxisName: PropTypes.string,
    yAxisName: PropTypes.string,
    numberSuffix: PropTypes.string,
    plottooltext: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired
      })
    )
  }),
};

export default StoreDynamicChart;
