import React from 'react';
import {connect} from "react-redux";
import Role from "../../models/role";
import MasterLayout from "../layouts/master";
import StoreLocations from "../common/store_locations";


const StoreLocationsPage = (props) => {
  return (
    <MasterLayout title='Store Locations'>
      {
        props.stores.current_store_id &&
        <StoreLocations store_id={props.stores.current_store_id}
                        can_create={Role.user_has_capability(Role.capabilities.create_own_locations)}
                        can_update={Role.user_has_capability(Role.capabilities.update_own_locations)}
                        can_delete={Role.user_has_capability(Role.capabilities.delete_own_locations)} />
      }
    </MasterLayout>

  );
};

StoreLocationsPage.propTypes = {};

const mapStateToProps = state => ({
  stores: state.stores
});

export default connect(mapStateToProps)(StoreLocationsPage);
