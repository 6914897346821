import React, {useEffect, useCallback} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import {StoreSurveysModel} from "../../models/store_surveys";
import config from "../../config";


const StoreSurveys = (props) => {

  const surveys = props.store_surveys[props.store_id] ?
    Object.values(props.store_surveys[props.store_id]) : null;

  const go_to_survey = useCallback((survey_id) => {
    if(props.location.pathname.startsWith('/fitted/'))
      props.history.push(config.routes.fitted.store_survey.replace(':store_id', props.store_id)
        .replace(':survey_id', survey_id));
    else
      props.history.push(config.routes.store.survey.replace(':survey_id', survey_id));
  }, [props.store_id]);

  useEffect(() => {
    if(props.store_id && !surveys)
      StoreSurveysModel.get_store_surveys(props.store_id);
    else if (surveys && surveys.length)
      go_to_survey(surveys[0].id);
  }, [surveys]);

  const onRowClick = useCallback((rowData, rowMeta) => {
    if (surveys && surveys.length > rowMeta.dataIndex)
      go_to_survey(surveys[rowMeta.dataIndex].id);
  }, [surveys]);

  if (!surveys)
    return <b>Loading ...</b>;

  return (
    <MUIDataTable
      title="Store Surveys"
      data={surveys}
      columns={StoreSurveysModel.columns}
      options={{
        filterType: "dropdown",
        responsive: "scrollFullHeight",  // scrollMaxHeight | scrollFullHeight | stacked
        print: false,
        onRowClick,
        selectableRowsOnClick: false,
      }}
    />
  );
};

StoreSurveys.propTypes = {
  store_id: PropTypes.string.isRequired,
  can_create: PropTypes.bool.isRequired,
  can_update: PropTypes.bool.isRequired,
  can_delete: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  store_surveys: {
    ...state.store_surveys
  },
});

export default withRouter(connect(mapStateToProps)(StoreSurveys));
