import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import MUIDataTable from "mui-datatables";
import {TableRow, TableCell, Dialog, DialogTitle,DialogContent,Grid, Tooltip, IconButton} from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import StoreEmployeeForm from './store_employee_form';
import PropTypes from 'prop-types';
import {StoreEmployeesModel} from "../../models/store_employee";


const StoreEmployees = (props) => {

  const [create_mode, set_create_mode] = useState(false);

  useEffect(() => {
    if(props.store_id && !props.store_employees[props.store_id])
      StoreEmployeesModel.get_store_employees_by_store_id(props.store_id);
  }, [props.store_id, props.store_employees]);

  if(!props.store_id || !props.store_employees[props.store_id])
    return <b>Loading ...</b>;

  return (
    <div>
      <Dialog
        scroll='body'
        fullWidth
        maxWidth='sm'
        open={create_mode}
        onClose={()=>set_create_mode(false)}
      >
        <DialogTitle>Create New Fitter</DialogTitle>
        <DialogContent>
          <StoreEmployeeForm store_id={props.store_id}
                             onClose={() => set_create_mode(false)} />
        </DialogContent>
      </Dialog>

      <MUIDataTable
        title="Store Fitters"
        data={props.store_employees[props.store_id]}
        columns={StoreEmployeesModel.columns}
        options={{
          filter: false,
          responsive: "scrollFullHeight",  // scrollMaxHeight | scrollFullHeight | stacked
          print: false,
          selectableRows: (props.can_delete ? 'multiple' : 'none'),
          selectableRowsOnClick: false,
          onRowsDelete: (rowsDeleted) => {
            if(props.can_delete) {
              rowsDeleted.data.forEach((row) => {
                const employee = props.store_employees[props.store_id][row.dataIndex];
                StoreEmployeesModel.delete_store_employee_by_employee_id(props.store_id, employee.id);
              });
            }
          },
          expandableRows: props.can_update,
          customToolbar: () => (
            props.can_create &&
            <Tooltip title='Create Fitter'>
              <IconButton onClick={() => set_create_mode(true)}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          ),
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            return (
              <TableRow style={{backgroundColor: '#eee'}}>
                <TableCell colSpan={colSpan}>
                  <Grid container style={{justifyContent: 'center'}}>
                    <Grid container item sm={12} md={6} >
                      <div className='header'>
                        <h2>Edit Fitter</h2>
                      </div>
                      <StoreEmployeeForm store_id={props.store_id}
                                         store_employee={props.store_employees[props.store_id][rowMeta.dataIndex]} />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            );
          },
        }}
      />
    </div>
  );
};

StoreEmployees.propTypes = {
  store_id: PropTypes.string.isRequired,
  can_create: PropTypes.bool.isRequired,
  can_update: PropTypes.bool.isRequired,
  can_delete: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  store_employees: {
    ...state.store_employees
  },
});

export default connect(mapStateToProps)(StoreEmployees);
