import React, {useState, useEffect, useCallback, useRef} from 'react';
import axios from 'axios';
import moment from 'moment';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import ExcelExport from 'fusioncharts/fusioncharts.excelexport';
import ReactFC from 'react-fusioncharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import {Grid, makeStyles} from '@material-ui/core'
import Loader from '../../../components/loader/loader';
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import DatePickerDropdown from "./date_picker";
import config from '../../../config';
import FiltersPopover from "./filters/filters_popover";
import LayoutPopover from "./layout/layout_popover";
import auth from "../../../models/auth";
import StoreDynamicCharts from "../custom_reports/store_dynamic_charts";
import { withSnackbar } from 'notistack';


ReactFC.fcRoot(FusionCharts, Charts, ExcelExport, FusionTheme);
FusionCharts.options.creditLabel = false;

const date_format = 'MM-DD-YYYY h:mm a';


const useStyles = makeStyles(() => ({
  stat_card: {
    margin: 4,
    padding: '4px 18px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    boxShadow: 'none',
    border: '1px solid #eee',
    borderBottom: '4px solid #00BCD4',

    '& label': {
      margin: 1,
      lineHeight: '40px',
      fontWeight: '700',
      fontSize: '18px',
      color: '#444E60'
    },
    '& strong': {
      fontSize: '26px',
      color: '#636363',
      fontWeight: '900',
      margin: '0 0 0 20px',
      lineHeight: '40px',
    }
  }
}));

function debounce(func){
  let timer;
  return function(event) {
    if(timer)
      clearTimeout(timer);
    timer = setTimeout(func, 100, event);
  };
}



function StoreReport(props) {

  const classes = useStyles();

  const is_not_ios_app = !!props.store_id;

  const query = new URLSearchParams(window.location.search);

  const [layout, set_layout] = useState({rows: 1, cols: 1});
  const [data, set_data] = useState(null);
  const [filters, set_filters] = useState({});
  const [start_date, set_start_date] = useState(query.has('start_date') && moment(query.get('start_date')).startOf('day').utc());
  const [end_date, set_end_date] = useState(query.has('end_date') && moment(query.get('end_date')).local().endOf('day').utc());

  const fetch_chart_data = useCallback((headers, params) => {
    if (headers && params && params.retailer_id) {
      axios
        .get(config.api_base_url + config.api_urls.report.main, {params, headers})
        .then(res => {
          set_data(res.data);
          console.log(res.data);
        })
        .catch((error) => {
          props.enqueueSnackbar('Loading chart data was unsuccessful.', {variant: 'error'});
          console.error(error);
          set_data({});
        });
    }
  }, []);

  const reset_request = useCallback((retailer_id, token) => {
    if (retailer_id && token) {
      const filter_ids = {};
      if (filters['locations'] && filters['locations'].length)
        filter_ids['locations'] = filters['locations'].map(v => v.id);

      if (filters['employees'] && filters['employees'].length)
        filter_ids['employees'] = filters['employees'].map(v => v.id);

      if (filters['shoes'] && filters['shoes'].length)
        filter_ids['shoes'] = filters['shoes'].map(v => v.id);

      if (filters['brands'] && filters['brands'].length)
        filter_ids['brands'] = filters['brands'].map(v => v.id);

      const params = ({
        retailer_id,
        start_date: start_date.format(),
        end_date: end_date.format(),
        ...filter_ids
      });

      const headers = {
        'authorization': `Bearer ${token}`
      };

      fetch_chart_data(headers, params);
    }

    set_data(null);
  }, [filters, start_date, end_date]);

  useEffect(() => {
    if (!start_date || !end_date || !props.store_id)
      return;
    // Component is accessed from the portal page
    auth
      .get_auth_token()
      .then((token) => {
        reset_request(props.store_id, token);
      });
  }, [filters, start_date, end_date, props.store_id]);

  useEffect(() => {
    if (!start_date || !end_date || !query.has('token'))
      return;
    // Component is accessed from the ios app
    const token = query.get('token');
    reset_request(props.match.params.store_id, token);
  }, [filters, start_date, end_date, window.location.search, props.match.params.store_id]);

  useEffect(() => {
    console.log('Filters', filters);
  }, [filters]);

  const container = useRef();
  const padding = 20;
  const container_width = container.current ? container.current.clientWidth - (padding * 2) : 100;
  const container_height = container.current ? container.current.clientHeight - padding : 100;

  useEffect(() => {
    const on_resize = debounce(() => set_layout({...layout}));
    window.addEventListener("resize", on_resize);
    return () => window.removeEventListener('resize', on_resize)
  }, [layout]);

  return (
    <div ref={container} style={{
      position: 'absolute', left: 0, right: 0, top: 65, bottom: 0, overflow: 'auto', padding: 10
    }}>
      <Grid container direction="row" style={{padding: 0}}>
        <Grid item sm={12} md={6}>
          {
            data &&
            <div style={{padding: '20px 5px 10px'}}>
              <strong>{data.store}</strong> {moment.utc(data.start_date).local().format(date_format)} to {moment.utc(data.end_date).local().format(date_format)}
            </div>
          }
        </Grid>
        <Grid item sm={12} md={6} style={{
          textAlign: 'right',
          padding:'0 4px',
          flexDirection: 'row',
          flexWrap: 'wrap',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end'
        }}>
          <LayoutPopover container_width={container_width}
                         container_height={container_height}
                         on_layout_change={set_layout} />
          {
            is_not_ios_app &&
            <>
              <FiltersPopover store_id={props.store_id}
                              on_filter_change={set_filters} />

              <DatePickerDropdown onStartDateChange={set_start_date}
                                  onEndDateChange={set_end_date} />
            </>
          }
        </Grid>
        {
          data && data.statistics &&
          <>
            <Grid item xs={12} sm={6} lg={3}>
              <div className={classes.stat_card}>
                <label>Total Fits</label>
                <strong>
                  { data.statistics.total_interactions }
                </strong>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <div className={classes.stat_card}>
                <label>Total Pulls</label>
                <strong>
                  { data.statistics.total_pulled_shoes }
                </strong>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <div className={classes.stat_card}>
                <label>Total Purchases</label>
                <strong>
                  { data.statistics.total_purchased_shoes }
                </strong>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <div className={classes.stat_card}>
                <label>Avg Pulls per Interaction</label>
                <strong>
                  { Math.round(data.statistics.total_interactions ? data.statistics.total_pulled_shoes / data.statistics.total_interactions : 0) }
                </strong>
              </div>
            </Grid>
          </>
        }
      </Grid>
      {
        !data && <Loader/>
      }

      {
        data &&
        <Grid container direction="row" className='chart-layout' style={{padding: 0}}>
          <StoreDynamicCharts store_data={data} chart_layout={layout}
                              store_id={props.store_id || props.match.params.store_id}
                              on_change={() => set_filters({...filters})} />
        </Grid>
      }
    </div>
  );
}

StoreReport.propTypes = {
  store_id: PropTypes.string,
};

export default withRouter(withSnackbar(StoreReport));
