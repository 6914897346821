import React, {useState, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SettingsIcon from '@material-ui/icons/Settings';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import StoreDynamicChart from "./store_dynamic_chart";
import { useDrag, useDrop } from 'react-dnd'
import { makeStyles } from '@material-ui/core/styles';
import StoreDynamicChartEditor from "./store_dynamic_chart_editor";
import {StoreReportModel} from "../../../models/store_report";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

const ItemTypes = {
  CHART: 'CHART',
};

const useStyles = makeStyles(() => ({
  chart_container: {
    position: 'relative',
    overflow: 'hidden',
    border: '1px solid #dadada',
    borderRadius: '3px',
    fontSize: 10,
    color: '#787878',
    margin: 4,
    height: 'calc(100% - 8px)',

    '& > div': {
      height: '100%'
    }
  },
  settings_button: {
    top: 8,
    right: 72,
    color: '#303030',
    position: 'absolute',
    zIndex: 10,
    padding: 6,
  },
  duplicate_button: {
    top: 8,
    right: 40,
    color: '#303030',
    position: 'absolute',
    zIndex: 10,
    padding: 6,
  },
  drag_handle: {
    position: 'absolute',
    top: '0',
    height: '50px !important',
    left: '0',
    width: '12px',
    textAlign: 'center',
    cursor: 'move',
    zIndex: '100',
    padding: '13px 0'
  }
}));

const StoreDynamicChartContainer = (props) => {

  const {store_id, config, index, id, on_move, chart_layout, on_change} = props;

  const classes = useStyles();

  const [edit_config, set_edit_config] = useState(false);

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.CHART,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      on_move(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  });

  const [{opacity, isDragging}, drag, preview] = useDrag({
    item: {type: ItemTypes.CHART, id, index},
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  });

  drag(drop(ref));

  const duplicate_chart = useCallback(() => {
    const new_config = {
      ...config,
      id: '', caption: 'Duplicate of ' + config.caption
    };

    StoreReportModel
      .create_store_custom_chart(store_id, new_config)
      .then((doc_ref) => {
        doc_ref.get().then((doc) => {
          set_edit_config({
            ...(doc.data()),
            id: doc.id,
          });
          on_change();
        });
      });
  }, [store_id, config]);

  return (
    <div style={{opacity, height: chart_layout.cell_height, width: chart_layout.cell_width}}>
      <div className={classes.chart_container}>
        <div ref={ref} className={classes.drag_handle}>
          <DragIndicatorIcon  ref={preview}/>
        </div>

        {
          config.editable &&
          <Tooltip title="Edit Settings" aria-label="Edit Settings">
            <IconButton aria-label="Settings" className={classes.settings_button}
                        onClick={() => set_edit_config(config)}>
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        }

        <Tooltip title="Duplicate" aria-label="Duplicate">
          <IconButton aria-label="Duplicate" className={classes.duplicate_button}
                      onClick={duplicate_chart}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>

        <StoreDynamicChart {...props} config={config} />

        {
          edit_config && edit_config.editable &&
          <StoreDynamicChartEditor {...props} config={edit_config}
                                   on_close={() => set_edit_config() } />
        }
      </div>
    </div>
  );
};


StoreDynamicChartContainer.propTypes = {
  store_id: PropTypes.string.isRequired,
  store_data: PropTypes.object.isRequired,
  on_change: PropTypes.func.isRequired,
  on_move: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  store_charts: state.store_charts
});

export default connect(mapStateToProps)(StoreDynamicChartContainer);
