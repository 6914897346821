import Model from "./base";
import Joi from '@hapi/joi';
import firebase from "firebase";
import {ReduxStore} from './redux';


const initialState = { };

export const storeUserRolesReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_STORE_USER_ROLES_SUCCESS:
      return {
        ...state,
        [action.payload.store_id]: action.payload.roles
      };

    default:
      return state;
  }
};


export const actionTypes = {
  GET_STORE_USER_ROLES_SUCCESS: 'GET_STORE_USER_ROLES_SUCCESS'
};


export class StoreUserRoleModel extends Model {

  static columns = [
    {
      name: "label",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "updated_at",
      label: "Updated At",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        filter: true,
        sort: true,
      }
    },
  ];

  static create_validation = Joi.object({
    label: Joi.string().min(2).max(50).required(),
    capabilities: Joi.object().required(),
  }).options({ stripUnknown: true, abortEarly: false});

  static edit_validation = Joi.object({
    label: Joi.string().min(2).max(50).required(),
    capabilities: Joi.object().required(),
  }).options({ stripUnknown: true, abortEarly: false});

  static get_store_user_roles(store_id) {

    firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('roles')
      .onSnapshot((docSnapshots) => {
        const payload = {
          roles: docSnapshots.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            created_at: StoreUserRoleModel.format_firestore_timestamp(doc.data().created_at),
            updated_at: StoreUserRoleModel.format_firestore_timestamp(doc.data().updated_at),
          })),
          store_id
        };
        ReduxStore.dispatch({type: actionTypes.GET_STORE_USER_ROLES_SUCCESS, payload});
      });
  }

  static create_store_user_role(store_id, user_role_data) {
    return new Promise((resolve, reject) => {
      const {value, error} = StoreUserRoleModel.create_validation.validate(user_role_data);

      if (error)
        return reject(this.formatError(error));

      firebase
        .firestore()
        .collection('stores')
        .doc(store_id)
        .collection('roles')
        .add({
          ...value,
          created_at: firebase.firestore.Timestamp.now(),
          updated_at: firebase.firestore.Timestamp.now()
        })
        .then(resolve)
        .catch(reject);
    });
  }

  static update_store_user_role(store_id, role_id, user_role_data) {
    return new Promise((resolve, reject) => {
      const {value, error} = StoreUserRoleModel.edit_validation.validate(user_role_data);

      if (error)
        return reject(StoreUserRoleModel.formatError(error));

      firebase
        .firestore()
        .collection('stores')
        .doc(store_id)
        .collection('roles')
        .doc(role_id)
        .update({
          ...value,
          updated_at: firebase.firestore.Timestamp.now()
        })
        .then(resolve)
        .catch(reject);
    });
  }

  static delete_store_user_role(store_id, role_id) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('stores')
        .doc(store_id)
        .collection('roles')
        .doc(role_id)
        .delete()
        .then(resolve)
        .catch(reject);
    });
  }

  static get_store_role_reference(store_id, role_id) {
    return firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('roles')
      .doc(role_id);
  }

}
