import React, {useState} from 'react';
import PropTypes from 'prop-types';
import StoreProfileForm from "./store_profile_form";
import numeral from 'numeral';
import {Button} from "@material-ui/core";


const StoreProfile = (props) => {

  const [edit_mode, set_edit_mode] = useState(false);

  if (!props.store)
    return null;

  if (props.can_edit && edit_mode) {
    return <StoreProfileForm store={props.store}
                             closeForm={() => set_edit_mode(false)}/>
  }

  return (
    <div>
      {
        props.can_edit &&
        <Button variant="contained" color="primary"
                onClick={() => set_edit_mode(true)}>
          Edit Store
        </Button>
      }
      <h3>{props.store.name}</h3>
      <p>{props.store.email}</p>
      <p>{props.store.street_address}</p>
      <p>{props.store.city}</p>
      <p>{props.store.state}</p>
      <p>{props.store.country}</p>
      <p>{props.store.zipcode}</p>
      <p>{props.store.website}</p>
      <p>{props.store.sq_ft ? numeral(props.store.sq_ft).format('0,0') + ' ft' : '-'}</p>
      <p>{props.store.net_sales ? numeral(props.store.net_sales).format('$ 0,0') : '-'}</p>
      <p>{props.store.median_income ? numeral(props.store.median_income).format('$ 0,0') : '-'}</p>
      <p>{props.store.tags}</p>
    </div>
  );
};

StoreProfile.propTypes = {
  store: PropTypes.object.isRequired,
  can_edit: PropTypes.bool.isRequired,
};

export default StoreProfile;
