import firebase from "firebase";
import Joi from '@hapi/joi';
import Model from "./base";
import {ReduxStore} from "./redux";
import {baseActionTypes} from "./base";
import numeral from 'numeral';


const initialState = { };

export const storeLocationsReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_STORE_LOCATIONS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};


export const actionTypes = {
  GET_STORE_LOCATIONS: 'GET_STORE_LOCATIONS',
};


export class StoreLocationsModel extends Model {

  static columns = [
    {
      name: "name",
      label: "Name",
      options: {
        // display: false,
        filter: true,
        sort: true,
      }
    },
    {
      name: "street_address",
      label: "Street Address",
      options: {
        // display: false,
        filter: true,
        sort: true,
      }
    },
    {
      name: "city",
      label: "City",
      options: {
        // display: false,
        filter: true,
        sort: true,
      }
    },
    {
      name: "state",
      label: "State",
      options: {
        // display: false,
        filter: true,
        sort: true,
      }
    },
    {
      name: "country",
      label: "Country",
      options: {
        display: false,
        filter: true,
        sort: true,
      }
    },
    {
      name: "zipcode",
      label: "Zip Code",
      options: {
        display: false,
        filter: true,
        sort: true,
      }
    },
    {
      name: "sq_ft",
      label: "Square Fit",
      options: {
        // display: false,
        filter: true,
        sort: true,
        customBodyRender: (v) => numeral(v).format('0,0') + ' ft'
      }
    },
  ];

  static create_schema = Joi.object({
    name: Joi.string().min(5).max(50).required(),
    street_address: Joi.string().required(),
    city: Joi.string().required(),
    state: Joi.string().required(),
    country: Joi.string().required(),
    zipcode: Joi.number().required(),
    sq_ft: Joi.number()
  }).options({ stripUnknown: true, abortEarly: false});

  static edit_schema = Joi.object({
    name: Joi.string().min(5).max(50).required(),
    street_address: Joi.string().required(),
    city: Joi.string().required(),
    state: Joi.string().required(),
    country: Joi.string().required(),
    zipcode: Joi.number().required(),
    sq_ft: Joi.number()
  }).options({ stripUnknown: true, abortEarly: false});

  static get_store_locations_by_store_id(store_id) {
    firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('locations')
      .where('deleted', '==', false)
      .onSnapshot((documentSnapshots) => {
        const result =  documentSnapshots.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          created_at: StoreLocationsModel.format_firestore_timestamp(doc.data().created_at),
          updated_at: StoreLocationsModel.format_firestore_timestamp(doc.data().updated_at),
        }));
        ReduxStore.dispatch({
          type: actionTypes.GET_STORE_LOCATIONS,
          payload: {[store_id]: result}
        });
      })
  }

  static create_store_location_by_store_id(store_id, location_data) {

    const {value, error} = this.create_schema.validate(location_data);

    if(error)
      return Promise.reject(this.formatError(error));

    const promise = firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('locations')
      .add({
        ...value,
        deleted: false,
        created_at: firebase.firestore.Timestamp.now(),
        updated_at: firebase.firestore.Timestamp.now()
      });

    promise
      .catch(error => {
        ReduxStore.dispatch({
          type: baseActionTypes.FIRE_STORE_ERROR,
          error
        });
      });

    return promise;
  }

  static update_store_location_by_location_id(store_id, location_id, location_data) {

    const {value, error} = StoreLocationsModel.edit_schema.validate(location_data);

    if(error)
      return Promise.reject(this.formatError(error));

    const promise = firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('locations')
      .doc(location_id)
      .update({
          ...value,
        updated_at: firebase.firestore.Timestamp.now()
      });

    promise
      .catch(error => {
        ReduxStore.dispatch({
          type: baseActionTypes.FIRE_STORE_ERROR,
          error
        });
      });

    return promise;
  }

  static delete_store_location_by_location_id(store_id, location_id) {

    const promise = firebase
      .firestore()
      .collection('stores')
      .doc(store_id)
      .collection('locations')
      .doc(location_id)
      .update({
        deleted: true
      });

    promise
      .catch(error => {
        ReduxStore.dispatch({
          type: baseActionTypes.FIRE_STORE_ERROR,
          error
        });
      });

    return promise;
  }

}
