import React from 'react';
import {connect} from "react-redux";
import MasterLayout from "../layouts/master";
import Role from "../../models/role";
import StoreUsers from "../common/store_users";


const StoreUsersPage = (props) => {
  return (
    <MasterLayout title='Store Users'>
      {
        props.stores.current_store_id &&
        <StoreUsers store_id={props.stores.current_store_id}
                    can_create={Role.user_has_capability(Role.capabilities.create_store_users)}
                    can_update={Role.user_has_capability(Role.capabilities.update_store_users)}
                    can_delete={Role.user_has_capability(Role.capabilities.delete_store_users)} />
      }
    </MasterLayout>
  );
};

StoreUsersPage.propTypes = {};

const mapStateToProps = state => ({
  stores: state.stores
});

export default connect(mapStateToProps)(StoreUsersPage);
