import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import MUIDataTable from "mui-datatables";
import MasterLayout from "../layouts/master";
import {TableRow, TableCell, Dialog, DialogTitle,DialogContent,Grid} from '@material-ui/core';
import {ShoeModel} from "../../models/shoe";
import ShoeForm from './shoe_form';
import ListToolbar from '../../components/list_toolbar/list_toolbar';
import Role from "../../models/role";
import ShoesSelection from '../common/shoes_selection';


const ShoesPage = (props) => {

  const [create_mode, set_create_mode] = useState(false);
  const [shoes_selection_mode, set_shoes_selection_mode] = useState(false);

  useEffect(() => {
    if(!props.shoes.items.length)
      ShoeModel.get_all_shoes();
  }, []);

  return (
    <MasterLayout title='Shoes'>

      <Dialog
        scroll='body'
        fullWidth
        maxWidth='sm'
        open={create_mode}
        onClose={()=>set_create_mode(false)}
      >
        <DialogTitle>Create New Shoes</DialogTitle>
        <DialogContent>
          <ShoeForm onClose={()=>set_create_mode(false)} />
        </DialogContent>
      </Dialog>

      <Dialog
        scroll='body'
        fullWidth
        maxWidth='sm'
        open={shoes_selection_mode}
        onClose={()=>set_shoes_selection_mode(false)}
      >
        <DialogTitle id="scroll-dialog-title">Select Visible Shoes</DialogTitle>
        <DialogContent>
          <ShoesSelection can_update={false} />
        </DialogContent>
      </Dialog>

      <MUIDataTable
        title="Shoes"
        data={props.shoes.items}
        columns={ShoeModel.columns}
        options={{
          filterType: "dropdown",
          responsive: "scrollFullHeight",  // scrollMaxHeight | scrollFullHeight | stacked
          print: false,
          selectableRows: 'none',
          expandableRows: Role.user_has_capability(Role.capabilities.update_common_shoes),
          customToolbar: () => (
            <ListToolbar
              addTitle='Create Shoes'
              onAddPress={
                Role.user_has_capability(Role.capabilities.create_common_shoes) &&
                (() => set_create_mode(true))
              }
              selectionTitle='Select Visible Shoes'
              onSelectionPress={
                Role.user_has_capability(Role.capabilities.update_all_shoes_selections) &&
                (() => set_shoes_selection_mode(true))
              }
            />
          ),
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            return (
              <TableRow style={{backgroundColor: '#eee'}}>
                <TableCell colSpan={colSpan}>
                  <Grid container style={{justifyContent: 'center'}}>
                    <Grid container item sm={12} md={6} >
                      <div className='header'>
                        <h2>Edit Shoes</h2>
                      </div>
                      <ShoeForm shoe={props.shoes.items[rowMeta.dataIndex]} />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            );
          },
        }}
      />
    </MasterLayout>
  );
};

const mapStateToProps = state => ({
  shoes: state.shoes,
  role: state.role
});

export default connect(mapStateToProps)(ShoesPage);
